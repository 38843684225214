
.platforma {

  /*!
 * Bootstrap v4.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
  @import url("https://fonts.googleapis.com/css?family=Germania+One");
  :root {
    --blue: #0065f2;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #ff3b30;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #7f7f7f;
    --gray-dark: #343a40;
    --primary: #0065f2;
    --secondary: #7f7f7f;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #ff3b30;
    --light: #eff0f0;
    --dark: #000000;
    --white: #fff;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

  *,
  *::before,
  *::after {
    box-sizing: border-box; }

  html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

  @-ms-viewport {
    width: device-width; }

  article, aside, dialog, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block; }

  body {
    margin: 0;
    font-family: "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    text-align: left;
    background-color: #fff;
    -webkit-font-smoothing: antialiased; }

  [tabindex="-1"]:focus {
    outline: 0 !important; }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible; }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 1.25rem; }

  p {
    margin-top: 0;
    margin-bottom: 1rem; }

  abbr[title],
  abbr[data-original-title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0; }

  address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit; }

  ol,
  ul,
  dl {
    margin-top: 0;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0.5rem; }

  ol ol,
  ul ul,
  ol ul,
  ul ol {
    margin-bottom: 0; }

  li {
    list-style-position: inside;
    margin-bottom: 0.5rem; }

  dt {
    font-weight: 700; }

  dd {
    margin-bottom: .5rem;
    margin-left: 0; }

  blockquote {
    margin: 0 0 1rem; }

  dfn {
    font-style: italic; }

  b,
  strong {
    font-weight: bolder; }

  small {
    font-size: 80%; }

  sub,
  sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline; }

  sub {
    bottom: -.25em; }

  sup {
    top: -.5em; }

  a {
    color: #0065f2;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects; }
  a:hover {
    color: #005ad9;
    text-decoration: none; }
  a:hover span {
    border-bottom: 1px solid; }
  a.transparent-link {
    opacity: 0.3; }
  a.transparent-link:hover {
    opacity: 1; }
  a.transparent-invert-link {
    opacity: 1; }
  a.transparent-invert-link:hover {
    opacity: 0.3; }
  a.action-link {
    font-weight: 500; }
  a.action-link::after {
    content: '';
    display: inline-block;
    width: 5.5px;
    height: 5.5px;
    border-top: 1.5px solid;
    border-right: 1.5px solid;
    transform: rotate(45deg);
    position: relative;
    margin: -1px 0 0 6px;
    vertical-align: middle;
    top: -1px; }

  a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

  pre,
  code,
  kbd,
  samp {
    font-family: monospace, monospace;
    font-size: 1em; }

  pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar; }

  figure {
    margin: 0 0 1rem; }

  img {
    vertical-align: middle;
    border-style: none; }

  svg:not(:root) {
    overflow: hidden; }

  table {
    border-collapse: collapse; }

  caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: #7f7f7f;
    text-align: left;
    caption-side: bottom; }

  th {
    text-align: inherit; }

  label {
    display: inline-block;
    margin-bottom: 0.375rem; }

  button {
    border-radius: 0; }

  button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color; }

  input,
  button,
  select,
  optgroup,
  textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit; }

  button,
  input {
    overflow: visible; }

  button,
  select {
    text-transform: none; }

  button,
  html [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button; }

  button::-moz-focus-inner,
  [type="button"]::-moz-focus-inner,
  [type="reset"]::-moz-focus-inner,
  [type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none; }

  input[type="radio"],
  input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0; }

  input[type="date"],
  input[type="time"],
  input[type="datetime-local"],
  input[type="month"] {
    -webkit-appearance: listbox; }

  textarea {
    overflow: auto;
    resize: vertical; }

  fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0; }

  legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal; }

  progress {
    vertical-align: baseline; }

  [type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto; }

  [type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none; }

  [type="search"]::-webkit-search-cancel-button,
  [type="search"]::-webkit-search-decoration {
    -webkit-appearance: none; }

  ::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button; }

  output {
    display: inline-block; }

  summary {
    display: list-item;
    cursor: pointer; }

  template {
    display: none; }

  [hidden] {
    display: none !important; }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: inherit;
    font-weight: 700;
    color: inherit;
    margin-bottom: 1.25rem; }

  h1, .h1 {
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 1rem;
    letter-spacing: -0.5px; }
  @media (min-width: 576px) {
    h1, .h1 {
      font-size: 2rem; } }
  @media (min-width: 992px) {
    h1, .h1 {
      font-size: 2.5rem;
      margin-bottom: 1.25rem; } }

  h2, .h2 {
    font-size: 1.5rem;
    line-height: 1.33;
    margin-bottom: 1rem;
    letter-spacing: -0.1px; }

  h3, .h3 {
    font-size: 1.125rem;
    line-height: 1.55;
    font-weight: 500;
    margin-bottom: 1rem;
    letter-spacing: 0; }

  h4, .h4 {
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 1rem;
    letter-spacing: 0.1px; }

  h5, .h5 {
    font-size: 0.875rem;
    line-height: 1.42;
    font-weight: 500;
    margin-bottom: 1rem;
    letter-spacing: 0; }

  h6, .h6 {
    font-size: 0.875rem;
    line-height: 1.5;
    font-weight: 500;
    margin-bottom: 1rem;
    letter-spacing: 0; }

  .lead {
    font-size: 1.00125rem;
    font-weight: 400; }
  @media (min-width: 992px) {
    .lead {
      font-size: 1.125rem; } }

  .display-1 {
    font-size: 6rem;
    font-weight: 700;
    line-height: 1.07;
    margin-bottom: 1.5rem; }

  .display-2 {
    font-size: 5.5rem;
    font-weight: 700;
    line-height: 1.07;
    margin-bottom: 1.5rem; }

  .display-3 {
    font-size: 4.5rem;
    font-weight: 700;
    line-height: 1.07;
    margin-bottom: 1.5rem; }

  .display-4 {
    font-size: 1.9985rem;
    font-weight: 700;
    line-height: 1.07;
    margin-bottom: 1.5rem; }
  @media (min-width: 576px) {
    .display-4 {
      font-size: 2.499rem; } }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1); }

  small,
  .small {
    font-size: 87.5%;
    font-weight: 400; }

  .large {
    font-size: 99%;
    font-weight: 400;
    line-height: 1.55; }
  @media (min-width: 992px) {
    .large {
      font-size: 112.5%; } }

  mark,
  .mark {
    padding: 0.2em;
    background-color: #fcf8e3; }

  .list-unstyled {
    padding-left: 0;
    list-style: none; }

  .list-inline {
    padding-left: 0;
    list-style: none; }

  .list-inline-item {
    display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

  .initialism {
    font-size: 90%;
    text-transform: uppercase; }

  .blockquote {
    margin-bottom: 2.5rem;
    font-size: 1.5rem; }

  .blockquote-footer {
    display: block;
    font-size: 80%;
    color: #7f7f7f; }
  .blockquote-footer::before {
    content: "\2014 \00A0"; }

  .weight-300 {
    font-weight: 300; }

  .weight-400 {
    font-weight: 400; }

  .weight-500 {
    font-weight: 500; }

  .weight-700 {
    font-weight: 700; }

  .weight-900 {
    font-weight: 900; }

  .price-large {
    font-size: 4.125rem;
    line-height: 4.125rem;
    font-weight: 300;
    height: 4.125rem;
    letter-spacing: -5.69px; }

  .price-medium {
    font-size: 3.5rem;
    line-height: 3.75rem;
    font-weight: 700;
    height: 3.75rem;
    letter-spacing: -1px; }

  .price-small {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: 700;
    height: 3rem;
    letter-spacing: -0.5px; }

  .price-unit {
    font-size: 1.125rem;
    font-weight: 500;
    letter-spacing: 0; }

  .img-fluid {
    max-width: 100%;
    height: auto; }

  .img-thumbnail {
    padding: 0.25rem;
    background-color: #fff;
    border: 1px solid #e7e7e7;
    max-width: 100%;
    height: auto; }

  .figure {
    display: inline-block; }

  .figure-img {
    margin-bottom: 0.5rem;
    line-height: 1; }

  .figure-caption {
    font-size: 90%;
    color: #7f7f7f; }

  code,
  kbd,
  pre,
  samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

  code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word; }
  a > code {
    color: inherit; }

  kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #212529; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

  pre {
    display: block;
    font-size: 87.5%;
    color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

  .pre-scrollable {
    max-height: 340px;
    overflow-y: scroll; }

  .container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 768px) {
    .container {
      padding-right: 40px;
      padding-left: 40px; } }
  @media (min-width: 576px) {
    .container {
      max-width: 768px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 992px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 1100px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1200px; } }

  .container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto; }
  @media (min-width: 768px) {
    .container-fluid {
      padding-right: 40px;
      padding-left: 40px; } }

  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px; }

  .no-gutters {
    margin-right: 0;
    margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }

  .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }

  .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none; }

  .col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }

  .col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }

  .col-3 {
    flex: 0 0 25%;
    max-width: 25%; }

  .col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }

  .col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }

  .col-6 {
    flex: 0 0 50%;
    max-width: 50%; }

  .col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }

  .col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }

  .col-9 {
    flex: 0 0 75%;
    max-width: 75%; }

  .col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }

  .col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }

  .col-12 {
    flex: 0 0 100%;
    max-width: 100%; }

  .order-first {
    order: -1; }

  .order-last {
    order: 13; }

  .order-0 {
    order: 0; }

  .order-1 {
    order: 1; }

  .order-2 {
    order: 2; }

  .order-3 {
    order: 3; }

  .order-4 {
    order: 4; }

  .order-5 {
    order: 5; }

  .order-6 {
    order: 6; }

  .order-7 {
    order: 7; }

  .order-8 {
    order: 8; }

  .order-9 {
    order: 9; }

  .order-10 {
    order: 10; }

  .order-11 {
    order: 11; }

  .order-12 {
    order: 12; }

  .offset-1 {
    margin-left: 8.33333%; }

  .offset-2 {
    margin-left: 16.66667%; }

  .offset-3 {
    margin-left: 25%; }

  .offset-4 {
    margin-left: 33.33333%; }

  .offset-5 {
    margin-left: 41.66667%; }

  .offset-6 {
    margin-left: 50%; }

  .offset-7 {
    margin-left: 58.33333%; }

  .offset-8 {
    margin-left: 66.66667%; }

  .offset-9 {
    margin-left: 75%; }

  .offset-10 {
    margin-left: 83.33333%; }

  .offset-11 {
    margin-left: 91.66667%; }

  @media (min-width: 576px) {
    .col-sm {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .col-sm-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .col-sm-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-sm-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-sm-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-sm-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-sm-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-sm-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-sm-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-sm-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-sm-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-sm-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-sm-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-sm-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-sm-first {
      order: -1; }
    .order-sm-last {
      order: 13; }
    .order-sm-0 {
      order: 0; }
    .order-sm-1 {
      order: 1; }
    .order-sm-2 {
      order: 2; }
    .order-sm-3 {
      order: 3; }
    .order-sm-4 {
      order: 4; }
    .order-sm-5 {
      order: 5; }
    .order-sm-6 {
      order: 6; }
    .order-sm-7 {
      order: 7; }
    .order-sm-8 {
      order: 8; }
    .order-sm-9 {
      order: 9; }
    .order-sm-10 {
      order: 10; }
    .order-sm-11 {
      order: 11; }
    .order-sm-12 {
      order: 12; }
    .offset-sm-0 {
      margin-left: 0; }
    .offset-sm-1 {
      margin-left: 8.33333%; }
    .offset-sm-2 {
      margin-left: 16.66667%; }
    .offset-sm-3 {
      margin-left: 25%; }
    .offset-sm-4 {
      margin-left: 33.33333%; }
    .offset-sm-5 {
      margin-left: 41.66667%; }
    .offset-sm-6 {
      margin-left: 50%; }
    .offset-sm-7 {
      margin-left: 58.33333%; }
    .offset-sm-8 {
      margin-left: 66.66667%; }
    .offset-sm-9 {
      margin-left: 75%; }
    .offset-sm-10 {
      margin-left: 83.33333%; }
    .offset-sm-11 {
      margin-left: 91.66667%; } }

  @media (min-width: 768px) {
    .col-md {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .col-md-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .col-md-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-md-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-md-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-md-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-md-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-md-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-md-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-md-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-md-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-md-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-md-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-md-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-md-first {
      order: -1; }
    .order-md-last {
      order: 13; }
    .order-md-0 {
      order: 0; }
    .order-md-1 {
      order: 1; }
    .order-md-2 {
      order: 2; }
    .order-md-3 {
      order: 3; }
    .order-md-4 {
      order: 4; }
    .order-md-5 {
      order: 5; }
    .order-md-6 {
      order: 6; }
    .order-md-7 {
      order: 7; }
    .order-md-8 {
      order: 8; }
    .order-md-9 {
      order: 9; }
    .order-md-10 {
      order: 10; }
    .order-md-11 {
      order: 11; }
    .order-md-12 {
      order: 12; }
    .offset-md-0 {
      margin-left: 0; }
    .offset-md-1 {
      margin-left: 8.33333%; }
    .offset-md-2 {
      margin-left: 16.66667%; }
    .offset-md-3 {
      margin-left: 25%; }
    .offset-md-4 {
      margin-left: 33.33333%; }
    .offset-md-5 {
      margin-left: 41.66667%; }
    .offset-md-6 {
      margin-left: 50%; }
    .offset-md-7 {
      margin-left: 58.33333%; }
    .offset-md-8 {
      margin-left: 66.66667%; }
    .offset-md-9 {
      margin-left: 75%; }
    .offset-md-10 {
      margin-left: 83.33333%; }
    .offset-md-11 {
      margin-left: 91.66667%; } }

  @media (min-width: 992px) {
    .col-lg {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .col-lg-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .col-lg-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-lg-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-lg-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-lg-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-lg-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-lg-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-lg-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-lg-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-lg-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-lg-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-lg-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-lg-first {
      order: -1; }
    .order-lg-last {
      order: 13; }
    .order-lg-0 {
      order: 0; }
    .order-lg-1 {
      order: 1; }
    .order-lg-2 {
      order: 2; }
    .order-lg-3 {
      order: 3; }
    .order-lg-4 {
      order: 4; }
    .order-lg-5 {
      order: 5; }
    .order-lg-6 {
      order: 6; }
    .order-lg-7 {
      order: 7; }
    .order-lg-8 {
      order: 8; }
    .order-lg-9 {
      order: 9; }
    .order-lg-10 {
      order: 10; }
    .order-lg-11 {
      order: 11; }
    .order-lg-12 {
      order: 12; }
    .offset-lg-0 {
      margin-left: 0; }
    .offset-lg-1 {
      margin-left: 8.33333%; }
    .offset-lg-2 {
      margin-left: 16.66667%; }
    .offset-lg-3 {
      margin-left: 25%; }
    .offset-lg-4 {
      margin-left: 33.33333%; }
    .offset-lg-5 {
      margin-left: 41.66667%; }
    .offset-lg-6 {
      margin-left: 50%; }
    .offset-lg-7 {
      margin-left: 58.33333%; }
    .offset-lg-8 {
      margin-left: 66.66667%; }
    .offset-lg-9 {
      margin-left: 75%; }
    .offset-lg-10 {
      margin-left: 83.33333%; }
    .offset-lg-11 {
      margin-left: 91.66667%; } }

  @media (min-width: 1200px) {
    .col-xl {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 100%; }
    .col-xl-auto {
      flex: 0 0 auto;
      width: auto;
      max-width: none; }
    .col-xl-1 {
      flex: 0 0 8.33333%;
      max-width: 8.33333%; }
    .col-xl-2 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; }
    .col-xl-3 {
      flex: 0 0 25%;
      max-width: 25%; }
    .col-xl-4 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%; }
    .col-xl-5 {
      flex: 0 0 41.66667%;
      max-width: 41.66667%; }
    .col-xl-6 {
      flex: 0 0 50%;
      max-width: 50%; }
    .col-xl-7 {
      flex: 0 0 58.33333%;
      max-width: 58.33333%; }
    .col-xl-8 {
      flex: 0 0 66.66667%;
      max-width: 66.66667%; }
    .col-xl-9 {
      flex: 0 0 75%;
      max-width: 75%; }
    .col-xl-10 {
      flex: 0 0 83.33333%;
      max-width: 83.33333%; }
    .col-xl-11 {
      flex: 0 0 91.66667%;
      max-width: 91.66667%; }
    .col-xl-12 {
      flex: 0 0 100%;
      max-width: 100%; }
    .order-xl-first {
      order: -1; }
    .order-xl-last {
      order: 13; }
    .order-xl-0 {
      order: 0; }
    .order-xl-1 {
      order: 1; }
    .order-xl-2 {
      order: 2; }
    .order-xl-3 {
      order: 3; }
    .order-xl-4 {
      order: 4; }
    .order-xl-5 {
      order: 5; }
    .order-xl-6 {
      order: 6; }
    .order-xl-7 {
      order: 7; }
    .order-xl-8 {
      order: 8; }
    .order-xl-9 {
      order: 9; }
    .order-xl-10 {
      order: 10; }
    .order-xl-11 {
      order: 11; }
    .order-xl-12 {
      order: 12; }
    .offset-xl-0 {
      margin-left: 0; }
    .offset-xl-1 {
      margin-left: 8.33333%; }
    .offset-xl-2 {
      margin-left: 16.66667%; }
    .offset-xl-3 {
      margin-left: 25%; }
    .offset-xl-4 {
      margin-left: 33.33333%; }
    .offset-xl-5 {
      margin-left: 41.66667%; }
    .offset-xl-6 {
      margin-left: 50%; }
    .offset-xl-7 {
      margin-left: 58.33333%; }
    .offset-xl-8 {
      margin-left: 66.66667%; }
    .offset-xl-9 {
      margin-left: 75%; }
    .offset-xl-10 {
      margin-left: 83.33333%; }
    .offset-xl-11 {
      margin-left: 91.66667%; } }

  .table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #e7e7e7; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e7e7e7; }
  .table tbody + tbody {
    border-top: 2px solid #e7e7e7; }
  .table .table {
    background-color: #fff; }

  .table-sm th,
  .table-sm td {
    padding: 0.3rem; }

  .table-bordered {
    border: 1px solid #e7e7e7; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #e7e7e7; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

  .table-borderless th,
  .table-borderless td,
  .table-borderless thead th,
  .table-borderless tbody + tbody {
    border: 0; }

  .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05); }

  .table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075); }

  .table-primary,
  .table-primary > th,
  .table-primary > td {
    background-color: #b8d4fb; }

  .table-hover .table-primary:hover {
    background-color: #a0c5fa; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #a0c5fa; }

  .table-secondary,
  .table-secondary > th,
  .table-secondary > td {
    background-color: #dbdbdb; }

  .table-hover .table-secondary:hover {
    background-color: #cecece; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #cecece; }

  .table-success,
  .table-success > th,
  .table-success > td {
    background-color: #c3e6cb; }

  .table-hover .table-success:hover {
    background-color: #b1dfbb; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1dfbb; }

  .table-info,
  .table-info > th,
  .table-info > td {
    background-color: #bee5eb; }

  .table-hover .table-info:hover {
    background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

  .table-warning,
  .table-warning > th,
  .table-warning > td {
    background-color: #ffeeba; }

  .table-hover .table-warning:hover {
    background-color: #ffe8a1; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffe8a1; }

  .table-danger,
  .table-danger > th,
  .table-danger > td {
    background-color: #ffc8c5; }

  .table-hover .table-danger:hover {
    background-color: #ffb0ac; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #ffb0ac; }

  .table-light,
  .table-light > th,
  .table-light > td {
    background-color: #fbfbfb; }

  .table-hover .table-light:hover {
    background-color: #eeeeee; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #eeeeee; }

  .table-dark,
  .table-dark > th,
  .table-dark > td {
    background-color: #b8b8b8; }

  .table-hover .table-dark:hover {
    background-color: #ababab; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #ababab; }

  .table-white,
  .table-white > th,
  .table-white > td {
    background-color: white; }

  .table-hover .table-white:hover {
    background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

  .table-active,
  .table-active > th,
  .table-active > td {
    background-color: rgba(0, 0, 0, 0.075); }

  .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

  .table .thead-dark th {
    color: #fff;
    background-color: #212529;
    border-color: #32383e; }

  .table .thead-light th {
    color: #777777;
    background-color: #f6f6f6;
    border-color: #e7e7e7; }

  .table-dark {
    color: #fff;
    background-color: #212529; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #32383e; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075); }

  @media (max-width: 575.98px) {
    .table-responsive-sm {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

  @media (max-width: 767.98px) {
    .table-responsive-md {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

  @media (max-width: 991.98px) {
    .table-responsive-lg {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

  @media (max-width: 1199.98px) {
    .table-responsive-xl {
      display: block;
      width: 100%;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar; }
  .table-responsive > .table-bordered {
    border: 0; }

  .form-control {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #000000;
    background-color: rgba(52, 58, 64, 0.06);
    background-clip: padding-box;
    border: 0 solid transparent;
    border-radius: 0;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #000000;
    background-color: rgba(52, 58, 64, 0.08);
    border-color: #73adff;
    outline: 0;
    box-shadow: 0 -1px 0 0 #777777 inset; }
  .form-control::placeholder {
    color: #7f7f7f;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    color: rgba(0, 0, 0, 0.2);
    background-color: #f6f6f6;
    opacity: 1; }
  .form-control:disabled::placeholder, .form-control[readonly]::placeholder {
    color: rgba(127, 127, 127, 0.2); }

  select.form-control:not([size]):not([multiple]) {
    height: calc(2.25rem + 0); }

  select.form-control:focus::-ms-value {
    color: #000000;
    background-color: rgba(52, 58, 64, 0.06); }

  .form-control-file,
  .form-control-range {
    display: block;
    width: 100%; }

  .col-form-label {
    padding-top: calc(0.5rem + 0);
    padding-bottom: calc(0.5rem + 0);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5; }

  .col-form-label-lg {
    padding-top: calc(0.75rem + 0);
    padding-bottom: calc(0.75rem + 0);
    font-size: 1.25rem;
    line-height: 1.5; }

  .col-form-label-sm {
    padding-top: calc(0.5rem + 0);
    padding-bottom: calc(0.5rem + 0);
    font-size: 0.875rem;
    line-height: 1.5; }

  .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #000000;
    background-color: transparent;
    border: solid transparent;
    border-width: 0 0; }
  .form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
  .input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
  .input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0; }

  .form-control-sm, .input-group-sm > .form-control,
  .input-group-sm > .input-group-prepend > .input-group-text,
  .input-group-sm > .input-group-append > .input-group-text,
  .input-group-sm > .input-group-prepend > .btn,
  .input-group-sm > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    line-height: 1.5; }

  select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(1.8125rem + 0); }

  .form-control-lg, .input-group-lg > .form-control,
  .input-group-lg > .input-group-prepend > .input-group-text,
  .input-group-lg > .input-group-append > .input-group-text,
  .input-group-lg > .input-group-prepend > .btn,
  .input-group-lg > .input-group-append > .btn {
    padding: 0.75rem 1rem;
    font-size: 1rem;
    line-height: 1.5; }

  select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
  .input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
  .input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
  .input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(3.25rem + 0); }

  .form-group {
    margin-bottom: 1rem; }

  .form-text {
    display: block;
    margin-top: 0.25rem; }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem; }

  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #7f7f7f; }

  .form-check-label {
    margin-bottom: 0; }

  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #28a745; }

  .valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(40, 167, 69, 0.8);
    border-radius: .2rem; }

  .was-validated .form-control:valid, .form-control.is-valid, .was-validated
  .custom-select:valid,
  .custom-select.is-valid {
    border-color: #28a745;
    color: #28a745; }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated
  .custom-select:valid:focus,
  .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 -1px 0 0 #28a745 inset; }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip, .was-validated
  .custom-select:valid ~ .valid-feedback,
  .was-validated
  .custom-select:valid ~ .valid-tooltip,
  .custom-select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip {
    display: block; }

  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }

  .was-validated .form-check-input:valid ~ .valid-feedback,
  .was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
  .form-check-input.is-valid ~ .valid-tooltip {
    display: block; }

  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #71dd8a; }

  .was-validated .custom-control-input:valid ~ .valid-feedback,
  .was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
  .custom-control-input.is-valid ~ .valid-tooltip {
    display: block; }

  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #34ce57; }

  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(40, 167, 69, 0.25); }

  .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: #28a745; }
  .was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit; }

  .was-validated .custom-file-input:valid ~ .valid-feedback,
  .was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
  .custom-file-input.is-valid ~ .valid-tooltip {
    display: block; }

  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.25); }

  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 87.5%;
    color: #ff3b30; }

  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: #fff;
    background-color: rgba(255, 59, 48, 0.8);
    border-radius: .2rem; }

  .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated
  .custom-select:invalid,
  .custom-select.is-invalid {
    border-color: #ff3b30;
    color: #ff3b30; }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated
  .custom-select:invalid:focus,
  .custom-select.is-invalid:focus {
    border-color: #ff3b30;
    box-shadow: 0 -1px 0 0 #ff3b30 inset; }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip, .was-validated
  .custom-select:invalid ~ .invalid-feedback,
  .was-validated
  .custom-select:invalid ~ .invalid-tooltip,
  .custom-select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip {
    display: block; }

  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #ff3b30; }

  .was-validated .form-check-input:invalid ~ .invalid-feedback,
  .was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
  .form-check-input.is-invalid ~ .invalid-tooltip {
    display: block; }

  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: #ff3b30; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #ffb4b0; }

  .was-validated .custom-control-input:invalid ~ .invalid-feedback,
  .was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
  .custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block; }

  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #ff6b63; }

  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #fff, 0 0 0 0 rgba(255, 59, 48, 0.25); }

  .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: #ff3b30; }
  .was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit; }

  .was-validated .custom-file-input:invalid ~ .invalid-feedback,
  .was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
  .custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block; }

  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0 rgba(255, 59, 48, 0.25); }

  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

  .btn {
    display: inline-block;
    font-weight: 500;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media screen and (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover, .btn:focus {
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 -1px 0 0 #777777 inset; }
  .btn.disabled, .btn:disabled {
    opacity: 0.4; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }
  .btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none; }

  a.btn.disabled,
  fieldset:disabled a.btn {
    pointer-events: none; }

  .btn-primary {
    color: #fff;
    background-color: #0065f2;
    border-color: #0065f2; }
  .btn-primary:hover {
    color: #fff;
    background-color: #005ad9;
    border-color: #005ad9; }
  .btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0 rgba(0, 101, 242, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0065f2;
    border-color: #0065f2; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0050c0;
    border-color: #0050c0; }
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 101, 242, 0.5); }

  .btn-secondary {
    color: #fff;
    background-color: #7f7f7f;
    border-color: #7f7f7f; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #727272;
    border-color: #727272; }
  .btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0 rgba(127, 127, 127, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #7f7f7f;
    border-color: #7f7f7f; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #666666;
    border-color: #666666; }
  .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(127, 127, 127, 0.5); }

  .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:hover {
    color: #fff;
    background-color: #23933d;
    border-color: #23933d; }
  .btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1e7e34; }
  .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

  .btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #148ea2;
    border-color: #148ea2; }
  .btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b; }
  .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

  .btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:hover {
    color: #212529;
    background-color: #edb200;
    border-color: #edb200; }
  .btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d49f00;
    border-color: #d49f00; }
  .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

  .btn-danger {
    color: #fff;
    background-color: #ff3b30;
    border-color: #ff3b30; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ff2317;
    border-color: #ff2317; }
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0 rgba(255, 59, 48, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #ff3b30;
    border-color: #ff3b30; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #fd0d00;
    border-color: #fd0d00; }
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 59, 48, 0.5); }

  .btn-light {
    color: #212529;
    background-color: #eff0f0;
    border-color: #eff0f0; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e4e4;
    border-color: #e2e4e4; }
  .btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0 rgba(239, 240, 240, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #eff0f0;
    border-color: #eff0f0; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d5d8d8;
    border-color: #d5d8d8; }
  .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(239, 240, 240, 0.5); }

  .btn-dark {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-dark:hover {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #000000;
    border-color: #000000; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: black;
    border-color: black; }
  .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }

  .btn-white {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:hover {
    color: #212529;
    background-color: #f2f2f2;
    border-color: #f2f2f2; }
  .btn-white:focus, .btn-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #e6e6e6; }
  .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
  .show > .btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

  .btn-outline-primary {
    color: #0065f2;
    background-color: transparent;
    background-image: none;
    border-color: rgba(0, 101, 242, 0.16); }
  .btn-outline-primary:hover {
    background-color: rgba(0, 101, 242, 0.16);
    border-color: transparent; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(0, 101, 242, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0065f2;
    background-color: transparent;
    border-color: rgba(0, 101, 242, 0.16); }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    background-color: rgba(0, 101, 242, 0.16);
    border-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 101, 242, 0.5); }

  .btn-outline-secondary {
    color: #7f7f7f;
    background-color: transparent;
    background-image: none;
    border-color: rgba(127, 127, 127, 0.16); }
  .btn-outline-secondary:hover {
    background-color: rgba(127, 127, 127, 0.16);
    border-color: transparent; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(127, 127, 127, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #7f7f7f;
    background-color: transparent;
    border-color: rgba(127, 127, 127, 0.16); }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    background-color: rgba(127, 127, 127, 0.16);
    border-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(127, 127, 127, 0.5); }

  .btn-outline-success {
    color: #28a745;
    background-color: transparent;
    background-image: none;
    border-color: rgba(40, 167, 69, 0.16); }
  .btn-outline-success:hover {
    background-color: rgba(40, 167, 69, 0.16);
    border-color: transparent; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
    border-color: rgba(40, 167, 69, 0.16); }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    background-color: rgba(40, 167, 69, 0.16);
    border-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(40, 167, 69, 0.5); }

  .btn-outline-info {
    color: #17a2b8;
    background-color: transparent;
    background-image: none;
    border-color: rgba(23, 162, 184, 0.16); }
  .btn-outline-info:hover {
    background-color: rgba(23, 162, 184, 0.16);
    border-color: transparent; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
    border-color: rgba(23, 162, 184, 0.16); }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    background-color: rgba(23, 162, 184, 0.16);
    border-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

  .btn-outline-warning {
    color: #ffc107;
    background-color: transparent;
    background-image: none;
    border-color: rgba(255, 193, 7, 0.16); }
  .btn-outline-warning:hover {
    background-color: rgba(255, 193, 7, 0.16);
    border-color: transparent; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
    border-color: rgba(255, 193, 7, 0.16); }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    background-color: rgba(255, 193, 7, 0.16);
    border-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 193, 7, 0.5); }

  .btn-outline-danger {
    color: #ff3b30;
    background-color: transparent;
    background-image: none;
    border-color: rgba(255, 59, 48, 0.16); }
  .btn-outline-danger:hover {
    background-color: rgba(255, 59, 48, 0.16);
    border-color: transparent; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(255, 59, 48, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #ff3b30;
    background-color: transparent;
    border-color: rgba(255, 59, 48, 0.16); }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    background-color: rgba(255, 59, 48, 0.16);
    border-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 59, 48, 0.5); }

  .btn-outline-light {
    color: #eff0f0;
    background-color: transparent;
    background-image: none;
    border-color: rgba(239, 240, 240, 0.16); }
  .btn-outline-light:hover {
    background-color: rgba(239, 240, 240, 0.16);
    border-color: transparent; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(239, 240, 240, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #eff0f0;
    background-color: transparent;
    border-color: rgba(239, 240, 240, 0.16); }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    background-color: rgba(239, 240, 240, 0.16);
    border-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(239, 240, 240, 0.5); }

  .btn-outline-dark {
    color: #000000;
    background-color: transparent;
    background-image: none;
    border-color: rgba(0, 0, 0, 0.16); }
  .btn-outline-dark:hover {
    background-color: rgba(0, 0, 0, 0.16);
    border-color: transparent; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #000000;
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.16); }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    background-color: rgba(0, 0, 0, 0.16);
    border-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.5); }

  .btn-outline-white {
    color: #fff;
    background-color: transparent;
    background-image: none;
    border-color: rgba(255, 255, 255, 0.16); }
  .btn-outline-white:hover {
    background-color: rgba(255, 255, 255, 0.16);
    border-color: transparent; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent;
    border-color: rgba(255, 255, 255, 0.16); }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    background-color: rgba(255, 255, 255, 0.16);
    border-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
  .show > .btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

  .btn-link {
    font-weight: 400;
    color: #0065f2;
    background-color: transparent; }
  .btn-link:hover {
    color: #005ad9;
    text-decoration: none;
    background-color: transparent;
    border-color: transparent; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: none;
    border-color: transparent;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #7f7f7f;
    pointer-events: none; }

  .btn-lg, .btn-group-lg > .btn {
    padding: 0.6875rem 1.875rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0; }

  .btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0; }

  .btn-block {
    display: block;
    width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

  input[type="submit"].btn-block,
  input[type="reset"].btn-block,
  input[type="button"].btn-block {
    width: 100%; }

  .fade {
    transition: opacity 0.15s linear; }
  @media screen and (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

  .collapse:not(.show) {
    display: none; }

  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

  .dropup,
  .dropright,
  .dropdown,
  .dropleft {
    position: relative; }

  .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.6rem;
    vertical-align: middle;
    content: "";
    border-top: 0.42rem solid;
    border-right: 0.3rem solid transparent;
    border-bottom: 0;
    border-left: 0.3rem solid transparent; }

  .dropdown-toggle:empty::after {
    margin-left: 0; }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0 0;
    margin: 0.625rem 0 0;
    font-size: 1rem;
    color: #000000;
    text-align: left;
    list-style: none;
    background-color: #f6f6f6;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.15); }
  @media (max-width: 767.98px) {
    .dropdown-menu {
      margin: 0; } }

  .dropdown-menu-right {
    right: 0;
    left: auto; }

  .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.625rem; }

  .dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.6rem;
    vertical-align: middle;
    content: "";
    border-top: 0;
    border-right: 0.3rem solid transparent;
    border-bottom: 0.42rem solid;
    border-left: 0.3rem solid transparent; }

  .dropup .dropdown-toggle:empty::after {
    margin-left: 0; }

  .dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.625rem; }

  .dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.6rem;
    vertical-align: middle;
    content: "";
    border-top: 0.3rem solid transparent;
    border-right: 0;
    border-bottom: 0.3rem solid transparent;
    border-left: 0.3rem solid; }

  .dropright .dropdown-toggle:empty::after {
    margin-left: 0; }

  .dropright .dropdown-toggle::after {
    vertical-align: 0; }

  .dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.625rem; }

  .dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.6rem;
    vertical-align: middle;
    content: ""; }

  .dropleft .dropdown-toggle::after {
    display: none; }

  .dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255rem;
    vertical-align: 0.255rem;
    content: "";
    border-top: 0.3rem solid transparent;
    border-right: 0.3rem solid;
    border-bottom: 0.3rem solid transparent; }

  .dropleft .dropdown-toggle:empty::after {
    margin-left: 0; }

  .dropleft .dropdown-toggle::before {
    vertical-align: 0; }

  .dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto; }

  .dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid #f6f6f6; }

  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.75rem 0.75rem;
    clear: both;
    font-weight: 400;
    color: #000000;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #000000;
    text-decoration: none;
    background-color: #e7e7e7; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0065f2; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #7f7f7f;
    background-color: transparent; }

  .dropdown-menu.show {
    display: block; }

  .dropdown-header {
    display: block;
    padding: 0 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: #7f7f7f;
    white-space: nowrap; }

  .dropdown-item-text {
    display: block;
    padding: 0.75rem 0.75rem;
    color: #000000; }

  .btn-group,
  .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto; }
  .btn-group > .btn:hover,
  .btn-group-vertical > .btn:hover {
    z-index: 1; }
  .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
  .btn-group-vertical > .btn:focus,
  .btn-group-vertical > .btn:active,
  .btn-group-vertical > .btn.active {
    z-index: 1; }
  .btn-group .btn + .btn,
  .btn-group .btn + .btn-group,
  .btn-group .btn-group + .btn,
  .btn-group .btn-group + .btn-group,
  .btn-group-vertical .btn + .btn,
  .btn-group-vertical .btn + .btn-group,
  .btn-group-vertical .btn-group + .btn,
  .btn-group-vertical .btn-group + .btn-group {
    border-left: none; }

  .btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

  .btn-group > .btn:first-child {
    margin-left: 0; }

  .dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

  .btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem; }

  .btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 1.40625rem;
    padding-left: 1.40625rem; }

  .btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  .btn-group-vertical .btn,
  .btn-group-vertical .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn + .btn,
  .btn-group-vertical > .btn + .btn-group,
  .btn-group-vertical > .btn-group + .btn,
  .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0; }

  .btn-group-toggle > .btn,
  .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

  .input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%; }
  .input-group > .form-control,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file:focus {
    z-index: 3; }
  .input-group > .form-control + .form-control,
  .input-group > .form-control + .custom-select,
  .input-group > .form-control + .custom-file,
  .input-group > .custom-select + .form-control,
  .input-group > .custom-select + .custom-select,
  .input-group > .custom-select + .custom-file,
  .input-group > .custom-file + .form-control,
  .input-group > .custom-file + .custom-select,
  .input-group > .custom-file + .custom-file {
    margin-left: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }

  .input-group-prepend,
  .input-group-append {
    display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: 0; }

  .input-group-prepend {
    margin-right: 0; }

  .input-group-append {
    margin-left: 0; }

  .input-group-text {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    text-align: center;
    white-space: nowrap;
    background-color: #f6f6f6;
    border: 0 solid transparent; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

  .input-group-control {
    margin-left: -2.5rem;
    height: 2.5rem;
    width: 2.5rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3; }

  .input-group-sm > .input-group-control {
    margin-left: -2.5rem;
    height: 2.5rem;
    width: 2.5rem; }

  .input-group-lg > .input-group-control {
    margin-left: -3rem;
    height: 3rem;
    width: 3rem; }

  .custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 2.25rem; }

  .custom-control-inline {
    display: inline-flex;
    margin-right: 1rem; }

  .custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #000000;
    background-color: #eff0f0; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #7f7f7f; }
  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: #f6f6f6; }

  .custom-control-label {
    margin-bottom: 0; }
  .custom-control-label::before {
    position: absolute;
    top: 0rem;
    left: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: #eff0f0; }
  .custom-control-label::after {
    position: absolute;
    top: 0rem;
    left: 0;
    display: block;
    width: 1.5rem;
    height: 1.5rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%; }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #eff0f0; }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTVweCIgaGVpZ2h0PSIxMnB4IiB2aWV3Qm94PSIwIDAgMTUgMTIiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIiBzdHJva2UtbGluZWNhcD0ic3F1YXJlIj4gICAgICAgIDxnIGlkPSJDaGVja2JveGVzLS8tTGlnaHQtLy0tQWN0aXZlIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNS4wMDAwMDAsIC02LjAwMDAwMCkiIGZpbGwtcnVsZT0ibm9uemVybyIgc3Ryb2tlPSIjMDAwMDAwIiBzdHJva2Utd2lkdGg9IjEuNSI+ICAgICAgICAgICAgPHBvbHlsaW5lIGlkPSJQYXRoLTUiIHBvaW50cz0iNiAxMS41ODA1MTkxIDEwLjUwNzMxMTggMTYuMDg3ODMwOSAxOC43MjgwMjQ5IDcuODY3MTE3ODMiPjwvcG9seWxpbmU+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4="); }

  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #0065f2; }

  .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23000000' d='M0 2h4'/%3E%3C/svg%3E"); }

  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(52, 58, 64, 0.06); }

  .custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::after {
    opacity: 0.4; }

  .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(52, 58, 64, 0.06); }

  .custom-radio .custom-control-label::before {
    border-radius: 50%; }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #eff0f0; }

  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23000000'/%3E%3C/svg%3E"); }

  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(52, 58, 64, 0.06); }

  .custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::after {
    opacity: 0.4; }

  .custom-select {
    display: inline-block;
    position: relative;
    width: 100%;
    height: calc(2.25rem + 0);
    line-height: 1.5;
    color: #000000;
    vertical-align: middle;
    background: rgba(52, 58, 64, 0.06) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1rem center;
    background-size: 8px 10px;
    border: 0 solid transparent;
    border-radius: 0;
    appearance: none; }
  .custom-select:focus {
    border-color: transparent;
    outline: 0;
    box-shadow: none; }
  .custom-select:focus::-ms-value {
    color: #000000;
    background-color: rgba(52, 58, 64, 0.06); }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #7f7f7f;
    background-color: #f6f6f6; }
  .custom-select::-ms-expand {
    opacity: 0; }
  .custom-select select {
    -webkit-appearance: none;
    background: transparent;
    appearance: none;
    border: none;
    width: 100%;
    height: 100%;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    user-select: none;
    border-radius: 0;
    height: 100%;
    width: 100%;
    outline: none;
    text-indent: 1rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

  .custom-select-sm {
    height: calc(1.8125rem + 0);
    font-size: 87.5%; }
  .custom-select-sm select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

  .custom-select-lg {
    height: calc(3.25rem + 0);
    font-size: 100%; }
  .custom-select-lg select {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }

  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 0);
    margin-bottom: 0; }

  .custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 0);
    margin: 0;
    opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #73adff;
    box-shadow: 0 -1px 0 0 #777777 inset; }
  .custom-file-input:focus ~ .custom-file-label::after {
    border-color: #73adff; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }

  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 0);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000000;
    background-color: rgba(52, 58, 64, 0.06);
    border: 1px solid transparent; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(calc(2.25rem + 0) - 1px * 2);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #000000;
    content: "Browse";
    background-color: #f6f6f6;
    border-left: 1px solid transparent; }

  .custom-range {
    width: 100%;
    padding-left: 0;
    background-color: transparent;
    appearance: none; }
  .custom-range:focus {
    outline: none; }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0065f2;
    border: 0;
    appearance: none; }
  .custom-range::-webkit-slider-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #fff, 0 -1px 0 0 #777777 inset; }
  .custom-range::-webkit-slider-thumb:active {
    background-color: #a6cbff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7e7e7;
    border-color: transparent; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0065f2;
    border: 0;
    appearance: none; }
  .custom-range::-moz-range-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #fff, 0 -1px 0 0 #777777 inset; }
  .custom-range::-moz-range-thumb:active {
    background-color: #a6cbff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7e7e7;
    border-color: transparent; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0065f2;
    border: 0;
    appearance: none; }
  .custom-range::-ms-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #fff, 0 -1px 0 0 #777777 inset; }
  .custom-range::-ms-thumb:active {
    background-color: #a6cbff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #e7e7e7; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #e7e7e7; }

  ul.custom-list {
    padding-left: 1.875rem; }
  ul.custom-list li {
    list-style: none; }
  ul.custom-list li::before {
    content: '';
    position: absolute;
    border: 0.125rem solid;
    border-radius: 50%;
    width: 1.125rem;
    height: 1.125rem;
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTFweCIgaGVpZ2h0PSI4cHgiIHZpZXdCb3g9IjAgMCAxMSA4IiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPiAgICA8ZyBpZD0iUGFnZS0xIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoIiBmaWxsPSIjMDAwMDAwIiBwb2ludHM9IjMuOTU1Mzg0MTUgNS4zMTA2OTMzNyA5LjI1NDMzOTI2IDAgMTAuMzE2MTcyMyAxLjA1OTQ4NjA0IDMuOTU2ODgyMDIgNy40MzI4NjMzOSAwIDMuNDc4Mzk2NDYgMS4wNjAzMzYzNCAyLjQxNzQxMjU1Ij48L3BvbHlnb24+ICAgIDwvZz48L3N2Zz4=") center center no-repeat;
    margin-left: -1.875rem;
    margin-top: 0.25rem; }

  ol.custom-list {
    counter-reset: number-list;
    padding-left: 2.8125rem; }
  ol.custom-list li {
    list-style: none;
    counter-increment: number-list; }
  ol.custom-list li::before {
    content: counter(number-list);
    text-align: center;
    position: absolute;
    border-radius: 50%;
    line-height: 1.25rem;
    font-weight: 500;
    border: 0.125rem solid;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: -2.5rem; }

  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }

  .nav-link {
    display: block;
    padding: 0.5rem 0.75rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #7f7f7f; }

  .nav-tabs {
    border-bottom: 2px solid #fff; }
  .nav-tabs .nav-item {
    color: #e7e7e7;
    margin-bottom: -2px; }
  .nav-tabs .nav-link {
    color: #7f7f7f;
    border-bottom: 2px solid transparent; }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    color: #0065f2;
    border-color: #f6f6f6 #f6f6f6 #fff; }
  .nav-tabs .nav-link.disabled {
    color: #7f7f7f;
    background-color: transparent;
    border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #0065f2;
    background-color: #fff;
    border-color: #fff #fff #0065f2 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -2px; }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #0065f2; }

  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center; }

  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center; }

  .tab-content > .tab-pane {
    display: none; }

  .tab-content > .active {
    display: block; }

  .caret-down {
    position: relative;
    display: block;
    cursor: pointer; }
  .caret-down::after {
    content: '';
    display: inline-block;
    right: 0;
    top: 50%;
    margin-top: -0.2125rem;
    margin-right: 0.125rem;
    position: absolute;
    border-right: 0.125rem solid;
    border-bottom: 0.125rem solid;
    width: 0.425rem;
    height: 0.425rem;
    transform: rotate(225deg); }
  .caret-down.collapsed::after {
    transform: rotate(45deg); }

  .caret-up {
    position: relative;
    display: block;
    cursor: pointer; }
  .caret-up::after {
    content: '';
    display: inline-block;
    right: 0;
    top: 50%;
    margin-top: -0.2125rem;
    margin-right: 0.125rem;
    position: absolute;
    border-right: 0.125rem solid;
    border-bottom: 0.125rem solid;
    width: 0.425rem;
    height: 0.425rem;
    transform: rotate(45deg); }
  .caret-up.collapsed::after {
    transform: rotate(225deg); }

  .caret-right {
    position: relative;
    display: block;
    cursor: pointer; }
  .caret-right::after {
    content: '';
    display: inline-block;
    right: 0;
    top: 50%;
    margin-top: -0.2125rem;
    margin-right: 0.125rem;
    position: absolute;
    border-right: 0.125rem solid;
    border-bottom: 0.125rem solid;
    width: 0.425rem;
    height: 0.425rem;
    transform: rotate(-45deg); }

  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 0; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

  .navbar-brand {
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    margin-right: 0;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

  .navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center; }

  .navbar-toggler {
    padding: 0.25rem 0;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }
  .navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer; }

  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%; }

  @media (max-width: 575.98px) {
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }

  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-sm .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

  @media (max-width: 767.98px) {
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }

  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
    .navbar-expand-md .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

  @media (max-width: 991.98px) {
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }

  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-lg .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

  @media (max-width: 1199.98px) {
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      padding-right: 0;
      padding-left: 0; } }

  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand-xl .navbar-nav .nav-link {
      padding-right: 0.75rem;
      padding-left: 0.75rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
  .navbar-expand .navbar-nav .dropdown-menu {
    position: absolute; }
  .navbar-expand .navbar-nav .nav-link {
    padding-right: 0.75rem;
    padding-left: 0.75rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

  .navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

  .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

  .navbar-light .navbar-nav .show > .nav-link,
  .navbar-light .navbar-nav .active > .nav-link,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9); }

  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1); }

  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

  .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9); }

  .navbar-dark .navbar-brand {
    color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

  .navbar-dark .navbar-nav .show > .nav-link,
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .nav-link.active {
    color: #fff; }

  .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1); }

  .navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"); }

  .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
  .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: #fff; }

  .navbar-side {
    position: fixed;
    z-index: 9999;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    transform: translateX(-100%);
    transition: 0.2s ease background-color, 0s linear transform 0.2s; }
  .navbar-side .navbar-side-content {
    background: #fff;
    padding: 15px;
    padding-bottom: 30px;
    height: 100%;
    transform: translateX(-100%);
    transition: 0.2s ease transform; }
  .navbar-side .navbar-side-content .nav-link {
    font-size: 2rem;
    line-height: 2.375rem; }
  @media (min-width: 768px) {
    .navbar-side .navbar-side-content .nav-link {
      font-size: 2.5rem;
      line-height: 2.5rem; } }
  @media (min-width: 992px) {
    .navbar-side .navbar-side-content .nav-link {
      font-size: 3.5rem;
      line-height: 3.75rem; } }
  .navbar-side .navbar-side-content .dropdown-menu {
    right: 0; }
  @media (min-width: 576px) {
    .navbar-side .navbar-side-content {
      max-width: 40%; } }
  @media (min-width: 768px) {
    .navbar-side .navbar-side-content {
      padding-left: 40px;
      padding-right: 40px; } }
  @media (min-width: 992px) {
    .navbar-side .navbar-side-content {
      padding-top: 30px; } }
  .navbar-side .navbar-side-close {
    display: block;
    margin-left: -5px;
    display: inline-block; }
  .navbar-side.show {
    transform: translateX(0);
    background-color: rgba(0, 0, 0, 0.1);
    transition: 0.2s ease background-color, 0s linear transform 0s; }
  .navbar-side.show .navbar-side-content {
    transform: translateX(0); }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0 solid rgba(0, 0, 0, 0.125); }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }

  .card-body {
    flex: 1 1 auto;
    padding: 1.5rem; }

  .card-title {
    margin-bottom: 0.75rem; }

  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0; }

  .card-text:last-child {
    margin-bottom: 0; }

  .card-link:hover {
    text-decoration: none; }

  .card-link + .card-link {
    margin-left: 1.5rem; }

  .card-header {
    padding: 0.75rem 1.5rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 0 solid rgba(0, 0, 0, 0.125); }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

  .card-footer {
    padding: 0.75rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 0 solid rgba(0, 0, 0, 0.125); }

  .card-header-tabs {
    margin-right: -0.75rem;
    margin-bottom: -0.75rem;
    margin-left: -0.75rem;
    border-bottom: 0; }

  .card-header-pills {
    margin-right: -0.75rem;
    margin-left: -0.75rem; }

  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem; }

  .card-img {
    width: 100%; }

  .card-img-top {
    width: 100%; }

  .card-img-bottom {
    width: 100%; }

  .card-deck {
    display: flex;
    flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
    .card-deck .card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

  .card-group {
    display: flex;
    flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
    .card-group > .card + .card {
      margin-left: 0;
      border-left: 0; } }

  .card-columns .card {
    margin-bottom: 0.75rem; }

  @media (min-width: 576px) {
    .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

  .accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }

  .accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }

  .accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

  .accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

  .breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 1.25rem 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent; }

  .breadcrumb-item {
    margin-bottom: 0; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 1rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    content: "";
    display: inline-block;
    margin-right: 1rem;
    color: #999999;
    width: 5.5px;
    height: 5.5px;
    border-top: 1.5px solid #999999;
    border-right: 1.5px solid #999999;
    transform: rotate(45deg);
    position: relative;
    vertical-align: middle; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #7f7f7f; }

  .pagination {
    display: flex;
    padding-left: 0;
    list-style: none; }

  .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #0065f2;
    background-color: #fff;
    border: 1px solid #e7e7e7; }
  .page-link:hover {
    z-index: 2;
    color: #005ad9;
    text-decoration: none;
    background-color: #f6f6f6;
    border-color: #e7e7e7; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 -1px 0 0 #777777 inset; }
  .page-link:not(:disabled):not(.disabled) {
    cursor: pointer; }

  .page-item:first-child .page-link {
    margin-left: 0; }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #0065f2;
    border-color: #0065f2; }

  .page-item.disabled .page-link {
    color: #7f7f7f;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #e7e7e7; }

  .pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
    line-height: 1.5; }

  .pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5; }

  .badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline; }
  .badge:empty {
    display: none; }

  .btn .badge {
    position: relative;
    top: -1px; }

  .badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em; }

  .badge-primary {
    color: #0065f2;
    background-color: rgba(0, 101, 242, 0.12); }
  .badge-primary[href]:hover, .badge-primary[href]:focus {
    color: #0065f2;
    text-decoration: none;
    background-color: rgba(0, 101, 242, 0.12); }

  .badge-secondary {
    color: #7f7f7f;
    background-color: rgba(127, 127, 127, 0.12); }
  .badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: #7f7f7f;
    text-decoration: none;
    background-color: rgba(127, 127, 127, 0.12); }

  .badge-success {
    color: #28a745;
    background-color: rgba(40, 167, 69, 0.12); }
  .badge-success[href]:hover, .badge-success[href]:focus {
    color: #28a745;
    text-decoration: none;
    background-color: rgba(40, 167, 69, 0.12); }

  .badge-info {
    color: #17a2b8;
    background-color: rgba(23, 162, 184, 0.12); }
  .badge-info[href]:hover, .badge-info[href]:focus {
    color: #17a2b8;
    text-decoration: none;
    background-color: rgba(23, 162, 184, 0.12); }

  .badge-warning {
    color: #ffc107;
    background-color: rgba(255, 193, 7, 0.12); }
  .badge-warning[href]:hover, .badge-warning[href]:focus {
    color: #ffc107;
    text-decoration: none;
    background-color: rgba(255, 193, 7, 0.12); }

  .badge-danger {
    color: #ff3b30;
    background-color: rgba(255, 59, 48, 0.12); }
  .badge-danger[href]:hover, .badge-danger[href]:focus {
    color: #ff3b30;
    text-decoration: none;
    background-color: rgba(255, 59, 48, 0.12); }

  .badge-light {
    color: #eff0f0;
    background-color: rgba(239, 240, 240, 0.12); }
  .badge-light[href]:hover, .badge-light[href]:focus {
    color: #eff0f0;
    text-decoration: none;
    background-color: rgba(239, 240, 240, 0.12); }

  .badge-dark {
    color: #000000;
    background-color: rgba(0, 0, 0, 0.12); }
  .badge-dark[href]:hover, .badge-dark[href]:focus {
    color: #000000;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.12); }

  .badge-white {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.12); }
  .badge-white[href]:hover, .badge-white[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: rgba(255, 255, 255, 0.12); }

  .jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #f6f6f6; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

  .jumbotron-fluid {
    padding-right: 0;
    padding-left: 0; }

  .alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent; }

  .alert-heading {
    color: inherit; }

  .alert-link {
    font-weight: 700; }

  .alert-dismissible {
    padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

  .alert-primary {
    color: #00357e;
    background-color: #cce0fc;
    border-color: #b8d4fb; }
  .alert-primary hr {
    border-top-color: #a0c5fa; }
  .alert-primary .alert-link {
    color: #00204b; }

  .alert-secondary {
    color: #424242;
    background-color: #e5e5e5;
    border-color: #dbdbdb; }
  .alert-secondary hr {
    border-top-color: #cecece; }
  .alert-secondary .alert-link {
    color: #292929; }

  .alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb; }
  .alert-success hr {
    border-top-color: #b1dfbb; }
  .alert-success .alert-link {
    color: #0b2e13; }

  .alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb; }
  .alert-info hr {
    border-top-color: #abdde5; }
  .alert-info .alert-link {
    color: #062c33; }

  .alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba; }
  .alert-warning hr {
    border-top-color: #ffe8a1; }
  .alert-warning .alert-link {
    color: #533f03; }

  .alert-danger {
    color: #851f19;
    background-color: #ffd8d6;
    border-color: #ffc8c5; }
  .alert-danger hr {
    border-top-color: #ffb0ac; }
  .alert-danger .alert-link {
    color: #5a1511; }

  .alert-light {
    color: #7c7d7d;
    background-color: #fcfcfc;
    border-color: #fbfbfb; }
  .alert-light hr {
    border-top-color: #eeeeee; }
  .alert-light .alert-link {
    color: #636363; }

  .alert-dark {
    color: black;
    background-color: #cccccc;
    border-color: #b8b8b8; }
  .alert-dark hr {
    border-top-color: #ababab; }
  .alert-dark .alert-link {
    color: black; }

  .alert-white {
    color: #858585;
    background-color: white;
    border-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #6c6c6c; }

  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0; }
    to {
      background-position: 0 0; } }

  .progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.75rem;
    background-color: #f6f6f6; }

  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #0065f2;
    transition: width 0.6s ease; }
  @media screen and (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem; }

  .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite; }

  .media {
    display: flex;
    align-items: flex-start; }

  .media-body {
    flex: 1; }

  .gallery-item {
    margin-bottom: 20px; }
  @media (min-width: 768px) {
    .gallery-item {
      margin-bottom: 30px; } }

  .list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0; }

  .list-group-item-action {
    width: 100%;
    color: #777777;
    text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    color: #777777;
    text-decoration: none;
    background-color: #eff0f0; }
  .list-group-item-action:active {
    color: #000000;
    background-color: #f6f6f6; }

  .list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:last-child {
    margin-bottom: 0; }
  .list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #7f7f7f;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0065f2;
    border-color: #0065f2; }

  .list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0; }

  .list-group-flush:first-child .list-group-item:first-child {
    border-top: 0; }

  .list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0; }

  .list-group-item-primary {
    color: #00357e;
    background-color: #b8d4fb; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #00357e;
    background-color: #a0c5fa; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #00357e;
    border-color: #00357e; }

  .list-group-item-secondary {
    color: #424242;
    background-color: #dbdbdb; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #424242;
    background-color: #cecece; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #424242;
    border-color: #424242; }

  .list-group-item-success {
    color: #155724;
    background-color: #c3e6cb; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #155724;
    background-color: #b1dfbb; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #155724;
    border-color: #155724; }

  .list-group-item-info {
    color: #0c5460;
    background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

  .list-group-item-warning {
    color: #856404;
    background-color: #ffeeba; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #856404;
    border-color: #856404; }

  .list-group-item-danger {
    color: #851f19;
    background-color: #ffc8c5; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #851f19;
    background-color: #ffb0ac; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #851f19;
    border-color: #851f19; }

  .list-group-item-light {
    color: #7c7d7d;
    background-color: #fbfbfb; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7c7d7d;
    background-color: #eeeeee; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7c7d7d;
    border-color: #7c7d7d; }

  .list-group-item-dark {
    color: black;
    background-color: #b8b8b8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: black;
    background-color: #ababab; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: black;
    border-color: black; }

  .list-group-item-white {
    color: #858585;
    background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

  .close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5; }
  .close:hover, .close:focus {
    color: #000000;
    text-decoration: none;
    opacity: .75; }
  .close:not(:disabled):not(.disabled) {
    cursor: pointer; }

  button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }

  .modal-open {
    overflow: hidden; }

  .modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%); }
  @media screen and (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
      transition: none; } }
  .modal.show .modal-dialog {
    transform: translate(0, 0); }

  .modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2)); }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, 0.2);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    outline: 0; }

  .modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

  .modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 0 solid #f6f6f6; }

  .close {
    padding: 1rem;
    top: 0;
    right: 0;
    z-index: 2;
    margin-left: auto; }
  @media (min-width: 576px) {
    .close {
      position: absolute; } }

  .modal-title {
    margin-bottom: 0;
    line-height: 1.5; }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem; }
  @media (min-width: 576px) {
    .modal-body {
      padding: 4rem; } }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 4rem;
    border-top: 0 solid #f6f6f6; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

  .modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll; }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 540px;
      margin: 1.75rem auto; }
    .modal-dialog-centered {
      min-height: calc(100% - (1.75rem * 2)); }
    .modal-sm {
      max-width: 300px; } }

  @media (min-width: 992px) {
    .modal-lg {
      max-width: 800px; } }

  .tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
  .tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid; }

  .bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
  .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000000; }

  .bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000000; }

  .bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
  .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000000; }

  .bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
  .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000000; }

  .tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: #fff;
    text-align: center;
    background-color: #000000; }

  .popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2); }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0; }
  .popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid; }

  .bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem; }
  .bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0; }
  .bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25); }

  .bs-popover-top .arrow::after,
  .bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: #fff; }

  .bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem; }
  .bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0; }
  .bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25); }

  .bs-popover-right .arrow::after,
  .bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: #fff; }

  .bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem; }
  .bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1); }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem; }
  .bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25); }

  .bs-popover-bottom .arrow::after,
  .bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

  .bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem; }
  .bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0 0; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem; }
  .bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25); }

  .bs-popover-left .arrow::after,
  .bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: #fff; }

  .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb; }
  .popover-header:empty {
    display: none; }

  .popover-body {
    padding: 0.5rem 0.75rem;
    color: #000000; }

  .carousel {
    position: relative; }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden; }

  .carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    backface-visibility: hidden;
    perspective: 1000px; }
  @media screen and (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    display: block; }

  .carousel-item-next,
  .carousel-item-prev {
    position: absolute;
    top: 0; }

  .carousel-item-next.carousel-item-left,
  .carousel-item-prev.carousel-item-right {
    transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
      transform: translate3d(0, 0, 0); } }

  .carousel-item-next,
  .active.carousel-item-right {
    transform: translateX(100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-next,
    .active.carousel-item-right {
      transform: translate3d(100%, 0, 0); } }

  .carousel-item-prev,
  .active.carousel-item-left {
    transform: translateX(-100%); }
  @supports (transform-style: preserve-3d) {
    .carousel-item-prev,
    .active.carousel-item-left {
      transform: translate3d(-100%, 0, 0); } }

  .carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity; }

  .carousel-fade .carousel-item.active,
  .carousel-fade .carousel-item-next.carousel-item-left,
  .carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1; }

  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    opacity: 0; }

  .carousel-fade .carousel-item-next,
  .carousel-fade .carousel-item-prev,
  .carousel-fade .carousel-item.active,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-prev {
    transform: translateX(0); }
  @supports (transform-style: preserve-3d) {
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
      transform: translate3d(0, 0, 0); } }

  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    color: #000000;
    text-align: center;
    opacity: 0.5; }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #000000;
    text-decoration: none;
    outline: 0;
    opacity: 1; }
  .carousel-control-prev:hover.swiper-button-disabled, .carousel-control-prev:focus.swiper-button-disabled,
  .carousel-control-next:hover.swiper-button-disabled,
  .carousel-control-next:focus.swiper-button-disabled {
    opacity: 0.5;
    cursor: default; }

  .has-outside-pagination .carousel-control-prev,
  .has-outside-pagination .carousel-control-next {
    bottom: 26px; }

  .carousel-control-prev {
    left: 0; }
  .carousel-control-prev.carousel-control-outside {
    display: none; }
  @media (min-width: 1200px) {
    .carousel-control-prev.carousel-control-outside {
      display: flex;
      left: -97px; } }

  .carousel-control-next {
    right: 0; }
  .carousel-control-next.carousel-control-outside {
    display: none; }
  @media (min-width: 1200px) {
    .carousel-control-next.carousel-control-outside {
      display: flex;
      right: -97px; } }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    display: inline-block;
    width: 15px;
    height: 30px;
    background: transparent no-repeat center center;
    background-size: 100% 100%; }

  .carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxN3B4IiBoZWlnaHQ9IjMzcHgiIHZpZXdCb3g9IjAgMCAxNyAzMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5MaW5lPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJBcnJvd3MtLy1TbGlkZXItLy1CdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wMDAwMDAsIC03LjAwMDAwMCkiIGZpbGw9IiMwMDAwMDAiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJMaW5lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjQwNTcxNCwgMjMuNjk4Mjg2KSBzY2FsZSgtMSwgMSkgcm90YXRlKC05MC4wMDAwMDApIHRyYW5zbGF0ZSgtNy40MDU3MTQsIC0yMy42OTgyODYpICIgcG9pbnRzPSI3LjQwNTcxNDI5IDMwLjA0MzMzOTggMjIuMjE3MTQyOSAxNS4yMzE5MTEzIDIzLjI3NzgwMyAxNi4yOTI1NzE0IDcuNDA1NzE0MjkgMzIuMTY0NjYwMiAtOC40NjYzNzQ0NiAxNi4yOTI1NzE0IC03LjQwNTcxNDI5IDE1LjIzMTkxMTMiPjwvcG9seWdvbj4gICAgICAgIDwvZz4gICAgPC9nPjwvc3ZnPg=="); }

  .carousel-control-next-icon {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxN3B4IiBoZWlnaHQ9IjMzcHgiIHZpZXdCb3g9IjAgMCAxNyAzMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5MaW5lPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJTeW1ib2xzIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJBcnJvd3MtLy1TbGlkZXItLy1GdyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMS4wMDAwMDAsIC03LjAwMDAwMCkiIGZpbGw9IiMwMDAwMDAiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJMaW5lIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjQwNTcxNCwgMjMuNjk4Mjg2KSByb3RhdGUoLTkwLjAwMDAwMCkgdHJhbnNsYXRlKC03LjQwNTcxNCwgLTIzLjY5ODI4NikgIiBwb2ludHM9IjcuNDA1NzE0MjkgMzAuMDQzMzM5OCAyMi4yMTcxNDI5IDE1LjIzMTkxMTMgMjMuMjc3ODAzIDE2LjI5MjU3MTQgNy40MDU3MTQyOSAzMi4xNjQ2NjAyIC04LjQ2NjM3NDQ2IDE2LjI5MjU3MTQgLTcuNDA1NzE0MjkgMTUuMjMxOTExMyI+PC9wb2x5Z29uPiAgICAgICAgPC9nPiAgICA8L2c+PC9zdmc+"); }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 59px;
    margin-left: 59px;
    list-style: none; }
  .carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 6px;
    height: 6px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    text-indent: -999px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%; }
  .carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""; }
  .carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: ""; }
  .carousel-indicators .active {
    background-color: #000000; }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center; }

  .swiper {
    position: relative; }

  .swiper-overflow {
    position: relative;
    overflow: hidden; }

  .swiper-container {
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1; }

  .swiper-overflow .swiper-container {
    overflow: unset; }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box; }

  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform; }

  .swiper-pagination {
    left: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 20px;
    z-index: 2; }
  .swiper-pagination.swiper-pagination-outside {
    position: relative;
    bottom: 0;
    margin-top: 20px; }
  .swiper-pagination .swiper-pagination-bullet {
    position: relative;
    flex: 0 1 auto;
    width: 6px;
    height: 6px;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    text-indent: -999px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    outline: none; }
  .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #000000; }
  .swiper-pagination .swiper-pagination-bullet::before {
    content: '';
    position: absolute;
    top: -6px;
    bottom: -6px;
    left: -6px;
    right: -6px; }

  .align-baseline {
    vertical-align: baseline !important; }

  .align-top {
    vertical-align: top !important; }

  .align-middle {
    vertical-align: middle !important; }

  .align-bottom {
    vertical-align: bottom !important; }

  .align-text-bottom {
    vertical-align: text-bottom !important; }

  .align-text-top {
    vertical-align: text-top !important; }

  .bg-primary {
    background-color: #0065f2 !important; }

  a.bg-primary:hover, a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #0050bf !important; }

  .bg-secondary {
    background-color: #7f7f7f !important; }

  a.bg-secondary:hover, a.bg-secondary:focus,
  button.bg-secondary:hover,
  button.bg-secondary:focus {
    background-color: #666666 !important; }

  .bg-success {
    background-color: #28a745 !important; }

  a.bg-success:hover, a.bg-success:focus,
  button.bg-success:hover,
  button.bg-success:focus {
    background-color: #1e7e34 !important; }

  .bg-info {
    background-color: #17a2b8 !important; }

  a.bg-info:hover, a.bg-info:focus,
  button.bg-info:hover,
  button.bg-info:focus {
    background-color: #117a8b !important; }

  .bg-warning {
    background-color: #ffc107 !important; }

  a.bg-warning:hover, a.bg-warning:focus,
  button.bg-warning:hover,
  button.bg-warning:focus {
    background-color: #d39e00 !important; }

  .bg-danger {
    background-color: #ff3b30 !important; }

  a.bg-danger:hover, a.bg-danger:focus,
  button.bg-danger:hover,
  button.bg-danger:focus {
    background-color: #fc0d00 !important; }

  .bg-light {
    background-color: #eff0f0 !important; }

  a.bg-light:hover, a.bg-light:focus,
  button.bg-light:hover,
  button.bg-light:focus {
    background-color: #d5d7d7 !important; }

  .bg-dark {
    background-color: #000000 !important; }

  a.bg-dark:hover, a.bg-dark:focus,
  button.bg-dark:hover,
  button.bg-dark:focus {
    background-color: black !important; }

  .bg-white {
    background-color: #fff !important; }

  a.bg-white:hover, a.bg-white:focus,
  button.bg-white:hover,
  button.bg-white:focus {
    background-color: #e6e6e6 !important; }

  .bg-white {
    background-color: #fff !important; }

  .bg-transparent {
    background-color: transparent !important; }

  .bg-cover {
    background-color: #1a1d24; }

  .border {
    border: 1px solid #e7e7e7 !important; }

  .border-top {
    border-top: 1px solid #e7e7e7 !important; }

  .border-right {
    border-right: 1px solid #e7e7e7 !important; }

  .border-bottom {
    border-bottom: 1px solid #e7e7e7 !important; }

  .border-left {
    border-left: 1px solid #e7e7e7 !important; }

  .border-0 {
    border: 0 !important; }

  .border-top-0 {
    border-top: 0 !important; }

  .border-right-0 {
    border-right: 0 !important; }

  .border-bottom-0 {
    border-bottom: 0 !important; }

  .border-left-0 {
    border-left: 0 !important; }

  .border-primary {
    border-color: #0065f2 !important; }

  .border-secondary {
    border-color: #7f7f7f !important; }

  .border-success {
    border-color: #28a745 !important; }

  .border-info {
    border-color: #17a2b8 !important; }

  .border-warning {
    border-color: #ffc107 !important; }

  .border-danger {
    border-color: #ff3b30 !important; }

  .border-light {
    border-color: #eff0f0 !important; }

  .border-dark {
    border-color: #000000 !important; }

  .border-white {
    border-color: #fff !important; }

  .border-white {
    border-color: #fff !important; }

  .rounded {
    border-radius: 0 !important; }

  .rounded-top {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important; }

  .rounded-right {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important; }

  .rounded-bottom {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }

  .rounded-left {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important; }

  .rounded-circle {
    border-radius: 50% !important; }

  .rounded-0 {
    border-radius: 0 !important; }

  .clearfix::after {
    display: block;
    clear: both;
    content: ""; }

  .d-none {
    display: none !important; }

  .d-inline {
    display: inline !important; }

  .d-inline-block {
    display: inline-block !important; }

  .d-block {
    display: block !important; }

  .d-table {
    display: table !important; }

  .d-table-row {
    display: table-row !important; }

  .d-table-cell {
    display: table-cell !important; }

  .d-flex {
    display: flex !important; }

  .d-inline-flex {
    display: inline-flex !important; }

  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important; }
    .d-sm-inline {
      display: inline !important; }
    .d-sm-inline-block {
      display: inline-block !important; }
    .d-sm-block {
      display: block !important; }
    .d-sm-table {
      display: table !important; }
    .d-sm-table-row {
      display: table-row !important; }
    .d-sm-table-cell {
      display: table-cell !important; }
    .d-sm-flex {
      display: flex !important; }
    .d-sm-inline-flex {
      display: inline-flex !important; } }

  @media (min-width: 768px) {
    .d-md-none {
      display: none !important; }
    .d-md-inline {
      display: inline !important; }
    .d-md-inline-block {
      display: inline-block !important; }
    .d-md-block {
      display: block !important; }
    .d-md-table {
      display: table !important; }
    .d-md-table-row {
      display: table-row !important; }
    .d-md-table-cell {
      display: table-cell !important; }
    .d-md-flex {
      display: flex !important; }
    .d-md-inline-flex {
      display: inline-flex !important; } }

  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important; }
    .d-lg-inline {
      display: inline !important; }
    .d-lg-inline-block {
      display: inline-block !important; }
    .d-lg-block {
      display: block !important; }
    .d-lg-table {
      display: table !important; }
    .d-lg-table-row {
      display: table-row !important; }
    .d-lg-table-cell {
      display: table-cell !important; }
    .d-lg-flex {
      display: flex !important; }
    .d-lg-inline-flex {
      display: inline-flex !important; } }

  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important; }
    .d-xl-inline {
      display: inline !important; }
    .d-xl-inline-block {
      display: inline-block !important; }
    .d-xl-block {
      display: block !important; }
    .d-xl-table {
      display: table !important; }
    .d-xl-table-row {
      display: table-row !important; }
    .d-xl-table-cell {
      display: table-cell !important; }
    .d-xl-flex {
      display: flex !important; }
    .d-xl-inline-flex {
      display: inline-flex !important; } }

  @media print {
    .d-print-none {
      display: none !important; }
    .d-print-inline {
      display: inline !important; }
    .d-print-inline-block {
      display: inline-block !important; }
    .d-print-block {
      display: block !important; }
    .d-print-table {
      display: table !important; }
    .d-print-table-row {
      display: table-row !important; }
    .d-print-table-cell {
      display: table-cell !important; }
    .d-print-flex {
      display: flex !important; }
    .d-print-inline-flex {
      display: inline-flex !important; } }

  .embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

  .embed-responsive-21by9::before {
    padding-top: 42.85714%; }

  .embed-responsive-16by9::before {
    padding-top: 56.25%; }

  .embed-responsive-4by3::before {
    padding-top: 75%; }

  .embed-responsive-1by1::before {
    padding-top: 100%; }

  .flex-row {
    flex-direction: row !important; }

  .flex-column {
    flex-direction: column !important; }

  .flex-row-reverse {
    flex-direction: row-reverse !important; }

  .flex-column-reverse {
    flex-direction: column-reverse !important; }

  .flex-wrap {
    flex-wrap: wrap !important; }

  .flex-nowrap {
    flex-wrap: nowrap !important; }

  .flex-wrap-reverse {
    flex-wrap: wrap-reverse !important; }

  .flex-fill {
    flex: 1 1 auto !important; }

  .flex-grow-0 {
    flex-grow: 0 !important; }

  .flex-grow-1 {
    flex-grow: 1 !important; }

  .flex-shrink-0 {
    flex-shrink: 0 !important; }

  .flex-shrink-1 {
    flex-shrink: 1 !important; }

  .justify-content-start {
    justify-content: flex-start !important; }

  .justify-content-end {
    justify-content: flex-end !important; }

  .justify-content-center {
    justify-content: center !important; }

  .justify-content-between {
    justify-content: space-between !important; }

  .justify-content-around {
    justify-content: space-around !important; }

  .align-items-start {
    align-items: flex-start !important; }

  .align-items-end {
    align-items: flex-end !important; }

  .align-items-center {
    align-items: center !important; }

  .align-items-baseline {
    align-items: baseline !important; }

  .align-items-stretch {
    align-items: stretch !important; }

  .align-content-start {
    align-content: flex-start !important; }

  .align-content-end {
    align-content: flex-end !important; }

  .align-content-center {
    align-content: center !important; }

  .align-content-between {
    align-content: space-between !important; }

  .align-content-around {
    align-content: space-around !important; }

  .align-content-stretch {
    align-content: stretch !important; }

  .align-self-auto {
    align-self: auto !important; }

  .align-self-start {
    align-self: flex-start !important; }

  .align-self-end {
    align-self: flex-end !important; }

  .align-self-center {
    align-self: center !important; }

  .align-self-baseline {
    align-self: baseline !important; }

  .align-self-stretch {
    align-self: stretch !important; }

  @media (min-width: 576px) {
    .flex-sm-row {
      flex-direction: row !important; }
    .flex-sm-column {
      flex-direction: column !important; }
    .flex-sm-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-sm-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-sm-wrap {
      flex-wrap: wrap !important; }
    .flex-sm-nowrap {
      flex-wrap: nowrap !important; }
    .flex-sm-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .flex-sm-fill {
      flex: 1 1 auto !important; }
    .flex-sm-grow-0 {
      flex-grow: 0 !important; }
    .flex-sm-grow-1 {
      flex-grow: 1 !important; }
    .flex-sm-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-sm-shrink-1 {
      flex-shrink: 1 !important; }
    .justify-content-sm-start {
      justify-content: flex-start !important; }
    .justify-content-sm-end {
      justify-content: flex-end !important; }
    .justify-content-sm-center {
      justify-content: center !important; }
    .justify-content-sm-between {
      justify-content: space-between !important; }
    .justify-content-sm-around {
      justify-content: space-around !important; }
    .align-items-sm-start {
      align-items: flex-start !important; }
    .align-items-sm-end {
      align-items: flex-end !important; }
    .align-items-sm-center {
      align-items: center !important; }
    .align-items-sm-baseline {
      align-items: baseline !important; }
    .align-items-sm-stretch {
      align-items: stretch !important; }
    .align-content-sm-start {
      align-content: flex-start !important; }
    .align-content-sm-end {
      align-content: flex-end !important; }
    .align-content-sm-center {
      align-content: center !important; }
    .align-content-sm-between {
      align-content: space-between !important; }
    .align-content-sm-around {
      align-content: space-around !important; }
    .align-content-sm-stretch {
      align-content: stretch !important; }
    .align-self-sm-auto {
      align-self: auto !important; }
    .align-self-sm-start {
      align-self: flex-start !important; }
    .align-self-sm-end {
      align-self: flex-end !important; }
    .align-self-sm-center {
      align-self: center !important; }
    .align-self-sm-baseline {
      align-self: baseline !important; }
    .align-self-sm-stretch {
      align-self: stretch !important; } }

  @media (min-width: 768px) {
    .flex-md-row {
      flex-direction: row !important; }
    .flex-md-column {
      flex-direction: column !important; }
    .flex-md-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-md-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-md-wrap {
      flex-wrap: wrap !important; }
    .flex-md-nowrap {
      flex-wrap: nowrap !important; }
    .flex-md-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .flex-md-fill {
      flex: 1 1 auto !important; }
    .flex-md-grow-0 {
      flex-grow: 0 !important; }
    .flex-md-grow-1 {
      flex-grow: 1 !important; }
    .flex-md-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-md-shrink-1 {
      flex-shrink: 1 !important; }
    .justify-content-md-start {
      justify-content: flex-start !important; }
    .justify-content-md-end {
      justify-content: flex-end !important; }
    .justify-content-md-center {
      justify-content: center !important; }
    .justify-content-md-between {
      justify-content: space-between !important; }
    .justify-content-md-around {
      justify-content: space-around !important; }
    .align-items-md-start {
      align-items: flex-start !important; }
    .align-items-md-end {
      align-items: flex-end !important; }
    .align-items-md-center {
      align-items: center !important; }
    .align-items-md-baseline {
      align-items: baseline !important; }
    .align-items-md-stretch {
      align-items: stretch !important; }
    .align-content-md-start {
      align-content: flex-start !important; }
    .align-content-md-end {
      align-content: flex-end !important; }
    .align-content-md-center {
      align-content: center !important; }
    .align-content-md-between {
      align-content: space-between !important; }
    .align-content-md-around {
      align-content: space-around !important; }
    .align-content-md-stretch {
      align-content: stretch !important; }
    .align-self-md-auto {
      align-self: auto !important; }
    .align-self-md-start {
      align-self: flex-start !important; }
    .align-self-md-end {
      align-self: flex-end !important; }
    .align-self-md-center {
      align-self: center !important; }
    .align-self-md-baseline {
      align-self: baseline !important; }
    .align-self-md-stretch {
      align-self: stretch !important; } }

  @media (min-width: 992px) {
    .flex-lg-row {
      flex-direction: row !important; }
    .flex-lg-column {
      flex-direction: column !important; }
    .flex-lg-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-lg-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-lg-wrap {
      flex-wrap: wrap !important; }
    .flex-lg-nowrap {
      flex-wrap: nowrap !important; }
    .flex-lg-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .flex-lg-fill {
      flex: 1 1 auto !important; }
    .flex-lg-grow-0 {
      flex-grow: 0 !important; }
    .flex-lg-grow-1 {
      flex-grow: 1 !important; }
    .flex-lg-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-lg-shrink-1 {
      flex-shrink: 1 !important; }
    .justify-content-lg-start {
      justify-content: flex-start !important; }
    .justify-content-lg-end {
      justify-content: flex-end !important; }
    .justify-content-lg-center {
      justify-content: center !important; }
    .justify-content-lg-between {
      justify-content: space-between !important; }
    .justify-content-lg-around {
      justify-content: space-around !important; }
    .align-items-lg-start {
      align-items: flex-start !important; }
    .align-items-lg-end {
      align-items: flex-end !important; }
    .align-items-lg-center {
      align-items: center !important; }
    .align-items-lg-baseline {
      align-items: baseline !important; }
    .align-items-lg-stretch {
      align-items: stretch !important; }
    .align-content-lg-start {
      align-content: flex-start !important; }
    .align-content-lg-end {
      align-content: flex-end !important; }
    .align-content-lg-center {
      align-content: center !important; }
    .align-content-lg-between {
      align-content: space-between !important; }
    .align-content-lg-around {
      align-content: space-around !important; }
    .align-content-lg-stretch {
      align-content: stretch !important; }
    .align-self-lg-auto {
      align-self: auto !important; }
    .align-self-lg-start {
      align-self: flex-start !important; }
    .align-self-lg-end {
      align-self: flex-end !important; }
    .align-self-lg-center {
      align-self: center !important; }
    .align-self-lg-baseline {
      align-self: baseline !important; }
    .align-self-lg-stretch {
      align-self: stretch !important; } }

  @media (min-width: 1200px) {
    .flex-xl-row {
      flex-direction: row !important; }
    .flex-xl-column {
      flex-direction: column !important; }
    .flex-xl-row-reverse {
      flex-direction: row-reverse !important; }
    .flex-xl-column-reverse {
      flex-direction: column-reverse !important; }
    .flex-xl-wrap {
      flex-wrap: wrap !important; }
    .flex-xl-nowrap {
      flex-wrap: nowrap !important; }
    .flex-xl-wrap-reverse {
      flex-wrap: wrap-reverse !important; }
    .flex-xl-fill {
      flex: 1 1 auto !important; }
    .flex-xl-grow-0 {
      flex-grow: 0 !important; }
    .flex-xl-grow-1 {
      flex-grow: 1 !important; }
    .flex-xl-shrink-0 {
      flex-shrink: 0 !important; }
    .flex-xl-shrink-1 {
      flex-shrink: 1 !important; }
    .justify-content-xl-start {
      justify-content: flex-start !important; }
    .justify-content-xl-end {
      justify-content: flex-end !important; }
    .justify-content-xl-center {
      justify-content: center !important; }
    .justify-content-xl-between {
      justify-content: space-between !important; }
    .justify-content-xl-around {
      justify-content: space-around !important; }
    .align-items-xl-start {
      align-items: flex-start !important; }
    .align-items-xl-end {
      align-items: flex-end !important; }
    .align-items-xl-center {
      align-items: center !important; }
    .align-items-xl-baseline {
      align-items: baseline !important; }
    .align-items-xl-stretch {
      align-items: stretch !important; }
    .align-content-xl-start {
      align-content: flex-start !important; }
    .align-content-xl-end {
      align-content: flex-end !important; }
    .align-content-xl-center {
      align-content: center !important; }
    .align-content-xl-between {
      align-content: space-between !important; }
    .align-content-xl-around {
      align-content: space-around !important; }
    .align-content-xl-stretch {
      align-content: stretch !important; }
    .align-self-xl-auto {
      align-self: auto !important; }
    .align-self-xl-start {
      align-self: flex-start !important; }
    .align-self-xl-end {
      align-self: flex-end !important; }
    .align-self-xl-center {
      align-self: center !important; }
    .align-self-xl-baseline {
      align-self: baseline !important; }
    .align-self-xl-stretch {
      align-self: stretch !important; } }

  .float-left {
    float: left !important; }

  .float-right {
    float: right !important; }

  .float-none {
    float: none !important; }

  @media (min-width: 576px) {
    .float-sm-left {
      float: left !important; }
    .float-sm-right {
      float: right !important; }
    .float-sm-none {
      float: none !important; } }

  @media (min-width: 768px) {
    .float-md-left {
      float: left !important; }
    .float-md-right {
      float: right !important; }
    .float-md-none {
      float: none !important; } }

  @media (min-width: 992px) {
    .float-lg-left {
      float: left !important; }
    .float-lg-right {
      float: right !important; }
    .float-lg-none {
      float: none !important; } }

  @media (min-width: 1200px) {
    .float-xl-left {
      float: left !important; }
    .float-xl-right {
      float: right !important; }
    .float-xl-none {
      float: none !important; } }

  .position-static {
    position: static !important; }

  .position-relative {
    position: relative !important; }

  .position-absolute {
    position: absolute !important; }

  .position-fixed {
    position: fixed !important; }

  .position-sticky {
    position: sticky !important; }

  .fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030; }

  .fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030; }

  @supports (position: sticky) {
    .sticky-top {
      position: sticky;
      top: 0;
      z-index: 1020; } }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0; }

  .sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal; }

  .shadow-sm {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important; }

  .shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important; }

  .shadow-lg {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2) !important; }

  .shadow-none {
    box-shadow: none !important; }

  .w-25 {
    width: 25% !important; }

  .w-50 {
    width: 50% !important; }

  .w-75 {
    width: 75% !important; }

  .w-100 {
    width: 100% !important; }

  .w-auto {
    width: auto !important; }

  .h-25 {
    height: 25% !important; }

  .h-50 {
    height: 50% !important; }

  .h-75 {
    height: 75% !important; }

  .h-100 {
    height: 100% !important; }

  .h-auto {
    height: auto !important; }

  .mw-100 {
    max-width: 100% !important; }

  .mh-100 {
    max-height: 100% !important; }

  .m-0 {
    margin: 0 !important; }

  .mt-0,
  .my-0 {
    margin-top: 0 !important; }

  .mr-0,
  .mx-0 {
    margin-right: 0 !important; }

  .mb-0,
  .my-0 {
    margin-bottom: 0 !important; }

  .ml-0,
  .mx-0 {
    margin-left: 0 !important; }

  .m-1 {
    margin: 0.5rem !important; }

  .mt-1,
  .my-1 {
    margin-top: 0.5rem !important; }

  .mr-1,
  .mx-1 {
    margin-right: 0.5rem !important; }

  .mb-1,
  .my-1 {
    margin-bottom: 0.5rem !important; }

  .ml-1,
  .mx-1 {
    margin-left: 0.5rem !important; }

  .m-2 {
    margin: 1rem !important; }

  .mt-2,
  .my-2 {
    margin-top: 1rem !important; }

  .mr-2,
  .mx-2 {
    margin-right: 1rem !important; }

  .mb-2,
  .my-2 {
    margin-bottom: 1rem !important; }

  .ml-2,
  .mx-2 {
    margin-left: 1rem !important; }

  .m-3 {
    margin: 1.5rem !important; }

  .mt-3,
  .my-3 {
    margin-top: 1.5rem !important; }

  .mr-3,
  .mx-3 {
    margin-right: 1.5rem !important; }

  .mb-3,
  .my-3 {
    margin-bottom: 1.5rem !important; }

  .ml-3,
  .mx-3 {
    margin-left: 1.5rem !important; }

  .m-4 {
    margin: 2.5rem !important; }

  .mt-4,
  .my-4 {
    margin-top: 2.5rem !important; }

  .mr-4,
  .mx-4 {
    margin-right: 2.5rem !important; }

  .mb-4,
  .my-4 {
    margin-bottom: 2.5rem !important; }

  .ml-4,
  .mx-4 {
    margin-left: 2.5rem !important; }

  .m-5 {
    margin: 3.75rem !important; }

  .mt-5,
  .my-5 {
    margin-top: 3.75rem !important; }

  .mr-5,
  .mx-5 {
    margin-right: 3.75rem !important; }

  .mb-5,
  .my-5 {
    margin-bottom: 3.75rem !important; }

  .ml-5,
  .mx-5 {
    margin-left: 3.75rem !important; }

  .p-0 {
    padding: 0 !important; }

  .pt-0,
  .py-0 {
    padding-top: 0 !important; }

  .pr-0,
  .px-0 {
    padding-right: 0 !important; }

  .pb-0,
  .py-0 {
    padding-bottom: 0 !important; }

  .pl-0,
  .px-0 {
    padding-left: 0 !important; }

  .p-1 {
    padding: 0.5rem !important; }

  .pt-1,
  .py-1 {
    padding-top: 0.5rem !important; }

  .pr-1,
  .px-1 {
    padding-right: 0.5rem !important; }

  .pb-1,
  .py-1 {
    padding-bottom: 0.5rem !important; }

  .pl-1,
  .px-1 {
    padding-left: 0.5rem !important; }

  .p-2 {
    padding: 1rem !important; }

  .pt-2,
  .py-2 {
    padding-top: 1rem !important; }

  .pr-2,
  .px-2 {
    padding-right: 1rem !important; }

  .pb-2,
  .py-2 {
    padding-bottom: 1rem !important; }

  .pl-2,
  .px-2 {
    padding-left: 1rem !important; }

  .p-3 {
    padding: 1.5rem !important; }

  .pt-3,
  .py-3 {
    padding-top: 1.5rem !important; }

  .pr-3,
  .px-3 {
    padding-right: 1.5rem !important; }

  .pb-3,
  .py-3 {
    padding-bottom: 1.5rem !important; }

  .pl-3,
  .px-3 {
    padding-left: 1.5rem !important; }

  .p-4 {
    padding: 2.5rem !important; }

  .pt-4,
  .py-4 {
    padding-top: 2.5rem !important; }

  .pr-4,
  .px-4 {
    padding-right: 2.5rem !important; }

  .pb-4,
  .py-4 {
    padding-bottom: 2.5rem !important; }

  .pl-4,
  .px-4 {
    padding-left: 2.5rem !important; }

  .p-5 {
    padding: 3.75rem !important; }

  .pt-5,
  .py-5 {
    padding-top: 3.75rem !important; }

  .pr-5,
  .px-5 {
    padding-right: 3.75rem !important; }

  .pb-5,
  .py-5 {
    padding-bottom: 3.75rem !important; }

  .pl-5,
  .px-5 {
    padding-left: 3.75rem !important; }

  .m-auto {
    margin: auto !important; }

  .mt-auto,
  .my-auto {
    margin-top: auto !important; }

  .mr-auto,
  .mx-auto {
    margin-right: auto !important; }

  .mb-auto,
  .my-auto {
    margin-bottom: auto !important; }

  .ml-auto,
  .mx-auto {
    margin-left: auto !important; }

  @media (min-width: 576px) {
    .m-sm-0 {
      margin: 0 !important; }
    .mt-sm-0,
    .my-sm-0 {
      margin-top: 0 !important; }
    .mr-sm-0,
    .mx-sm-0 {
      margin-right: 0 !important; }
    .mb-sm-0,
    .my-sm-0 {
      margin-bottom: 0 !important; }
    .ml-sm-0,
    .mx-sm-0 {
      margin-left: 0 !important; }
    .m-sm-1 {
      margin: 0.5rem !important; }
    .mt-sm-1,
    .my-sm-1 {
      margin-top: 0.5rem !important; }
    .mr-sm-1,
    .mx-sm-1 {
      margin-right: 0.5rem !important; }
    .mb-sm-1,
    .my-sm-1 {
      margin-bottom: 0.5rem !important; }
    .ml-sm-1,
    .mx-sm-1 {
      margin-left: 0.5rem !important; }
    .m-sm-2 {
      margin: 1rem !important; }
    .mt-sm-2,
    .my-sm-2 {
      margin-top: 1rem !important; }
    .mr-sm-2,
    .mx-sm-2 {
      margin-right: 1rem !important; }
    .mb-sm-2,
    .my-sm-2 {
      margin-bottom: 1rem !important; }
    .ml-sm-2,
    .mx-sm-2 {
      margin-left: 1rem !important; }
    .m-sm-3 {
      margin: 1.5rem !important; }
    .mt-sm-3,
    .my-sm-3 {
      margin-top: 1.5rem !important; }
    .mr-sm-3,
    .mx-sm-3 {
      margin-right: 1.5rem !important; }
    .mb-sm-3,
    .my-sm-3 {
      margin-bottom: 1.5rem !important; }
    .ml-sm-3,
    .mx-sm-3 {
      margin-left: 1.5rem !important; }
    .m-sm-4 {
      margin: 2.5rem !important; }
    .mt-sm-4,
    .my-sm-4 {
      margin-top: 2.5rem !important; }
    .mr-sm-4,
    .mx-sm-4 {
      margin-right: 2.5rem !important; }
    .mb-sm-4,
    .my-sm-4 {
      margin-bottom: 2.5rem !important; }
    .ml-sm-4,
    .mx-sm-4 {
      margin-left: 2.5rem !important; }
    .m-sm-5 {
      margin: 3.75rem !important; }
    .mt-sm-5,
    .my-sm-5 {
      margin-top: 3.75rem !important; }
    .mr-sm-5,
    .mx-sm-5 {
      margin-right: 3.75rem !important; }
    .mb-sm-5,
    .my-sm-5 {
      margin-bottom: 3.75rem !important; }
    .ml-sm-5,
    .mx-sm-5 {
      margin-left: 3.75rem !important; }
    .p-sm-0 {
      padding: 0 !important; }
    .pt-sm-0,
    .py-sm-0 {
      padding-top: 0 !important; }
    .pr-sm-0,
    .px-sm-0 {
      padding-right: 0 !important; }
    .pb-sm-0,
    .py-sm-0 {
      padding-bottom: 0 !important; }
    .pl-sm-0,
    .px-sm-0 {
      padding-left: 0 !important; }
    .p-sm-1 {
      padding: 0.5rem !important; }
    .pt-sm-1,
    .py-sm-1 {
      padding-top: 0.5rem !important; }
    .pr-sm-1,
    .px-sm-1 {
      padding-right: 0.5rem !important; }
    .pb-sm-1,
    .py-sm-1 {
      padding-bottom: 0.5rem !important; }
    .pl-sm-1,
    .px-sm-1 {
      padding-left: 0.5rem !important; }
    .p-sm-2 {
      padding: 1rem !important; }
    .pt-sm-2,
    .py-sm-2 {
      padding-top: 1rem !important; }
    .pr-sm-2,
    .px-sm-2 {
      padding-right: 1rem !important; }
    .pb-sm-2,
    .py-sm-2 {
      padding-bottom: 1rem !important; }
    .pl-sm-2,
    .px-sm-2 {
      padding-left: 1rem !important; }
    .p-sm-3 {
      padding: 1.5rem !important; }
    .pt-sm-3,
    .py-sm-3 {
      padding-top: 1.5rem !important; }
    .pr-sm-3,
    .px-sm-3 {
      padding-right: 1.5rem !important; }
    .pb-sm-3,
    .py-sm-3 {
      padding-bottom: 1.5rem !important; }
    .pl-sm-3,
    .px-sm-3 {
      padding-left: 1.5rem !important; }
    .p-sm-4 {
      padding: 2.5rem !important; }
    .pt-sm-4,
    .py-sm-4 {
      padding-top: 2.5rem !important; }
    .pr-sm-4,
    .px-sm-4 {
      padding-right: 2.5rem !important; }
    .pb-sm-4,
    .py-sm-4 {
      padding-bottom: 2.5rem !important; }
    .pl-sm-4,
    .px-sm-4 {
      padding-left: 2.5rem !important; }
    .p-sm-5 {
      padding: 3.75rem !important; }
    .pt-sm-5,
    .py-sm-5 {
      padding-top: 3.75rem !important; }
    .pr-sm-5,
    .px-sm-5 {
      padding-right: 3.75rem !important; }
    .pb-sm-5,
    .py-sm-5 {
      padding-bottom: 3.75rem !important; }
    .pl-sm-5,
    .px-sm-5 {
      padding-left: 3.75rem !important; }
    .m-sm-auto {
      margin: auto !important; }
    .mt-sm-auto,
    .my-sm-auto {
      margin-top: auto !important; }
    .mr-sm-auto,
    .mx-sm-auto {
      margin-right: auto !important; }
    .mb-sm-auto,
    .my-sm-auto {
      margin-bottom: auto !important; }
    .ml-sm-auto,
    .mx-sm-auto {
      margin-left: auto !important; } }

  @media (min-width: 768px) {
    .m-md-0 {
      margin: 0 !important; }
    .mt-md-0,
    .my-md-0 {
      margin-top: 0 !important; }
    .mr-md-0,
    .mx-md-0 {
      margin-right: 0 !important; }
    .mb-md-0,
    .my-md-0 {
      margin-bottom: 0 !important; }
    .ml-md-0,
    .mx-md-0 {
      margin-left: 0 !important; }
    .m-md-1 {
      margin: 0.5rem !important; }
    .mt-md-1,
    .my-md-1 {
      margin-top: 0.5rem !important; }
    .mr-md-1,
    .mx-md-1 {
      margin-right: 0.5rem !important; }
    .mb-md-1,
    .my-md-1 {
      margin-bottom: 0.5rem !important; }
    .ml-md-1,
    .mx-md-1 {
      margin-left: 0.5rem !important; }
    .m-md-2 {
      margin: 1rem !important; }
    .mt-md-2,
    .my-md-2 {
      margin-top: 1rem !important; }
    .mr-md-2,
    .mx-md-2 {
      margin-right: 1rem !important; }
    .mb-md-2,
    .my-md-2 {
      margin-bottom: 1rem !important; }
    .ml-md-2,
    .mx-md-2 {
      margin-left: 1rem !important; }
    .m-md-3 {
      margin: 1.5rem !important; }
    .mt-md-3,
    .my-md-3 {
      margin-top: 1.5rem !important; }
    .mr-md-3,
    .mx-md-3 {
      margin-right: 1.5rem !important; }
    .mb-md-3,
    .my-md-3 {
      margin-bottom: 1.5rem !important; }
    .ml-md-3,
    .mx-md-3 {
      margin-left: 1.5rem !important; }
    .m-md-4 {
      margin: 2.5rem !important; }
    .mt-md-4,
    .my-md-4 {
      margin-top: 2.5rem !important; }
    .mr-md-4,
    .mx-md-4 {
      margin-right: 2.5rem !important; }
    .mb-md-4,
    .my-md-4 {
      margin-bottom: 2.5rem !important; }
    .ml-md-4,
    .mx-md-4 {
      margin-left: 2.5rem !important; }
    .m-md-5 {
      margin: 3.75rem !important; }
    .mt-md-5,
    .my-md-5 {
      margin-top: 3.75rem !important; }
    .mr-md-5,
    .mx-md-5 {
      margin-right: 3.75rem !important; }
    .mb-md-5,
    .my-md-5 {
      margin-bottom: 3.75rem !important; }
    .ml-md-5,
    .mx-md-5 {
      margin-left: 3.75rem !important; }
    .p-md-0 {
      padding: 0 !important; }
    .pt-md-0,
    .py-md-0 {
      padding-top: 0 !important; }
    .pr-md-0,
    .px-md-0 {
      padding-right: 0 !important; }
    .pb-md-0,
    .py-md-0 {
      padding-bottom: 0 !important; }
    .pl-md-0,
    .px-md-0 {
      padding-left: 0 !important; }
    .p-md-1 {
      padding: 0.5rem !important; }
    .pt-md-1,
    .py-md-1 {
      padding-top: 0.5rem !important; }
    .pr-md-1,
    .px-md-1 {
      padding-right: 0.5rem !important; }
    .pb-md-1,
    .py-md-1 {
      padding-bottom: 0.5rem !important; }
    .pl-md-1,
    .px-md-1 {
      padding-left: 0.5rem !important; }
    .p-md-2 {
      padding: 1rem !important; }
    .pt-md-2,
    .py-md-2 {
      padding-top: 1rem !important; }
    .pr-md-2,
    .px-md-2 {
      padding-right: 1rem !important; }
    .pb-md-2,
    .py-md-2 {
      padding-bottom: 1rem !important; }
    .pl-md-2,
    .px-md-2 {
      padding-left: 1rem !important; }
    .p-md-3 {
      padding: 1.5rem !important; }
    .pt-md-3,
    .py-md-3 {
      padding-top: 1.5rem !important; }
    .pr-md-3,
    .px-md-3 {
      padding-right: 1.5rem !important; }
    .pb-md-3,
    .py-md-3 {
      padding-bottom: 1.5rem !important; }
    .pl-md-3,
    .px-md-3 {
      padding-left: 1.5rem !important; }
    .p-md-4 {
      padding: 2.5rem !important; }
    .pt-md-4,
    .py-md-4 {
      padding-top: 2.5rem !important; }
    .pr-md-4,
    .px-md-4 {
      padding-right: 2.5rem !important; }
    .pb-md-4,
    .py-md-4 {
      padding-bottom: 2.5rem !important; }
    .pl-md-4,
    .px-md-4 {
      padding-left: 2.5rem !important; }
    .p-md-5 {
      padding: 3.75rem !important; }
    .pt-md-5,
    .py-md-5 {
      padding-top: 3.75rem !important; }
    .pr-md-5,
    .px-md-5 {
      padding-right: 3.75rem !important; }
    .pb-md-5,
    .py-md-5 {
      padding-bottom: 3.75rem !important; }
    .pl-md-5,
    .px-md-5 {
      padding-left: 3.75rem !important; }
    .m-md-auto {
      margin: auto !important; }
    .mt-md-auto,
    .my-md-auto {
      margin-top: auto !important; }
    .mr-md-auto,
    .mx-md-auto {
      margin-right: auto !important; }
    .mb-md-auto,
    .my-md-auto {
      margin-bottom: auto !important; }
    .ml-md-auto,
    .mx-md-auto {
      margin-left: auto !important; } }

  @media (min-width: 992px) {
    .m-lg-0 {
      margin: 0 !important; }
    .mt-lg-0,
    .my-lg-0 {
      margin-top: 0 !important; }
    .mr-lg-0,
    .mx-lg-0 {
      margin-right: 0 !important; }
    .mb-lg-0,
    .my-lg-0 {
      margin-bottom: 0 !important; }
    .ml-lg-0,
    .mx-lg-0 {
      margin-left: 0 !important; }
    .m-lg-1 {
      margin: 0.5rem !important; }
    .mt-lg-1,
    .my-lg-1 {
      margin-top: 0.5rem !important; }
    .mr-lg-1,
    .mx-lg-1 {
      margin-right: 0.5rem !important; }
    .mb-lg-1,
    .my-lg-1 {
      margin-bottom: 0.5rem !important; }
    .ml-lg-1,
    .mx-lg-1 {
      margin-left: 0.5rem !important; }
    .m-lg-2 {
      margin: 1rem !important; }
    .mt-lg-2,
    .my-lg-2 {
      margin-top: 1rem !important; }
    .mr-lg-2,
    .mx-lg-2 {
      margin-right: 1rem !important; }
    .mb-lg-2,
    .my-lg-2 {
      margin-bottom: 1rem !important; }
    .ml-lg-2,
    .mx-lg-2 {
      margin-left: 1rem !important; }
    .m-lg-3 {
      margin: 1.5rem !important; }
    .mt-lg-3,
    .my-lg-3 {
      margin-top: 1.5rem !important; }
    .mr-lg-3,
    .mx-lg-3 {
      margin-right: 1.5rem !important; }
    .mb-lg-3,
    .my-lg-3 {
      margin-bottom: 1.5rem !important; }
    .ml-lg-3,
    .mx-lg-3 {
      margin-left: 1.5rem !important; }
    .m-lg-4 {
      margin: 2.5rem !important; }
    .mt-lg-4,
    .my-lg-4 {
      margin-top: 2.5rem !important; }
    .mr-lg-4,
    .mx-lg-4 {
      margin-right: 2.5rem !important; }
    .mb-lg-4,
    .my-lg-4 {
      margin-bottom: 2.5rem !important; }
    .ml-lg-4,
    .mx-lg-4 {
      margin-left: 2.5rem !important; }
    .m-lg-5 {
      margin: 3.75rem !important; }
    .mt-lg-5,
    .my-lg-5 {
      margin-top: 3.75rem !important; }
    .mr-lg-5,
    .mx-lg-5 {
      margin-right: 3.75rem !important; }
    .mb-lg-5,
    .my-lg-5 {
      margin-bottom: 3.75rem !important; }
    .ml-lg-5,
    .mx-lg-5 {
      margin-left: 3.75rem !important; }
    .p-lg-0 {
      padding: 0 !important; }
    .pt-lg-0,
    .py-lg-0 {
      padding-top: 0 !important; }
    .pr-lg-0,
    .px-lg-0 {
      padding-right: 0 !important; }
    .pb-lg-0,
    .py-lg-0 {
      padding-bottom: 0 !important; }
    .pl-lg-0,
    .px-lg-0 {
      padding-left: 0 !important; }
    .p-lg-1 {
      padding: 0.5rem !important; }
    .pt-lg-1,
    .py-lg-1 {
      padding-top: 0.5rem !important; }
    .pr-lg-1,
    .px-lg-1 {
      padding-right: 0.5rem !important; }
    .pb-lg-1,
    .py-lg-1 {
      padding-bottom: 0.5rem !important; }
    .pl-lg-1,
    .px-lg-1 {
      padding-left: 0.5rem !important; }
    .p-lg-2 {
      padding: 1rem !important; }
    .pt-lg-2,
    .py-lg-2 {
      padding-top: 1rem !important; }
    .pr-lg-2,
    .px-lg-2 {
      padding-right: 1rem !important; }
    .pb-lg-2,
    .py-lg-2 {
      padding-bottom: 1rem !important; }
    .pl-lg-2,
    .px-lg-2 {
      padding-left: 1rem !important; }
    .p-lg-3 {
      padding: 1.5rem !important; }
    .pt-lg-3,
    .py-lg-3 {
      padding-top: 1.5rem !important; }
    .pr-lg-3,
    .px-lg-3 {
      padding-right: 1.5rem !important; }
    .pb-lg-3,
    .py-lg-3 {
      padding-bottom: 1.5rem !important; }
    .pl-lg-3,
    .px-lg-3 {
      padding-left: 1.5rem !important; }
    .p-lg-4 {
      padding: 2.5rem !important; }
    .pt-lg-4,
    .py-lg-4 {
      padding-top: 2.5rem !important; }
    .pr-lg-4,
    .px-lg-4 {
      padding-right: 2.5rem !important; }
    .pb-lg-4,
    .py-lg-4 {
      padding-bottom: 2.5rem !important; }
    .pl-lg-4,
    .px-lg-4 {
      padding-left: 2.5rem !important; }
    .p-lg-5 {
      padding: 3.75rem !important; }
    .pt-lg-5,
    .py-lg-5 {
      padding-top: 3.75rem !important; }
    .pr-lg-5,
    .px-lg-5 {
      padding-right: 3.75rem !important; }
    .pb-lg-5,
    .py-lg-5 {
      padding-bottom: 3.75rem !important; }
    .pl-lg-5,
    .px-lg-5 {
      padding-left: 3.75rem !important; }
    .m-lg-auto {
      margin: auto !important; }
    .mt-lg-auto,
    .my-lg-auto {
      margin-top: auto !important; }
    .mr-lg-auto,
    .mx-lg-auto {
      margin-right: auto !important; }
    .mb-lg-auto,
    .my-lg-auto {
      margin-bottom: auto !important; }
    .ml-lg-auto,
    .mx-lg-auto {
      margin-left: auto !important; } }

  @media (min-width: 1200px) {
    .m-xl-0 {
      margin: 0 !important; }
    .mt-xl-0,
    .my-xl-0 {
      margin-top: 0 !important; }
    .mr-xl-0,
    .mx-xl-0 {
      margin-right: 0 !important; }
    .mb-xl-0,
    .my-xl-0 {
      margin-bottom: 0 !important; }
    .ml-xl-0,
    .mx-xl-0 {
      margin-left: 0 !important; }
    .m-xl-1 {
      margin: 0.5rem !important; }
    .mt-xl-1,
    .my-xl-1 {
      margin-top: 0.5rem !important; }
    .mr-xl-1,
    .mx-xl-1 {
      margin-right: 0.5rem !important; }
    .mb-xl-1,
    .my-xl-1 {
      margin-bottom: 0.5rem !important; }
    .ml-xl-1,
    .mx-xl-1 {
      margin-left: 0.5rem !important; }
    .m-xl-2 {
      margin: 1rem !important; }
    .mt-xl-2,
    .my-xl-2 {
      margin-top: 1rem !important; }
    .mr-xl-2,
    .mx-xl-2 {
      margin-right: 1rem !important; }
    .mb-xl-2,
    .my-xl-2 {
      margin-bottom: 1rem !important; }
    .ml-xl-2,
    .mx-xl-2 {
      margin-left: 1rem !important; }
    .m-xl-3 {
      margin: 1.5rem !important; }
    .mt-xl-3,
    .my-xl-3 {
      margin-top: 1.5rem !important; }
    .mr-xl-3,
    .mx-xl-3 {
      margin-right: 1.5rem !important; }
    .mb-xl-3,
    .my-xl-3 {
      margin-bottom: 1.5rem !important; }
    .ml-xl-3,
    .mx-xl-3 {
      margin-left: 1.5rem !important; }
    .m-xl-4 {
      margin: 2.5rem !important; }
    .mt-xl-4,
    .my-xl-4 {
      margin-top: 2.5rem !important; }
    .mr-xl-4,
    .mx-xl-4 {
      margin-right: 2.5rem !important; }
    .mb-xl-4,
    .my-xl-4 {
      margin-bottom: 2.5rem !important; }
    .ml-xl-4,
    .mx-xl-4 {
      margin-left: 2.5rem !important; }
    .m-xl-5 {
      margin: 3.75rem !important; }
    .mt-xl-5,
    .my-xl-5 {
      margin-top: 3.75rem !important; }
    .mr-xl-5,
    .mx-xl-5 {
      margin-right: 3.75rem !important; }
    .mb-xl-5,
    .my-xl-5 {
      margin-bottom: 3.75rem !important; }
    .ml-xl-5,
    .mx-xl-5 {
      margin-left: 3.75rem !important; }
    .p-xl-0 {
      padding: 0 !important; }
    .pt-xl-0,
    .py-xl-0 {
      padding-top: 0 !important; }
    .pr-xl-0,
    .px-xl-0 {
      padding-right: 0 !important; }
    .pb-xl-0,
    .py-xl-0 {
      padding-bottom: 0 !important; }
    .pl-xl-0,
    .px-xl-0 {
      padding-left: 0 !important; }
    .p-xl-1 {
      padding: 0.5rem !important; }
    .pt-xl-1,
    .py-xl-1 {
      padding-top: 0.5rem !important; }
    .pr-xl-1,
    .px-xl-1 {
      padding-right: 0.5rem !important; }
    .pb-xl-1,
    .py-xl-1 {
      padding-bottom: 0.5rem !important; }
    .pl-xl-1,
    .px-xl-1 {
      padding-left: 0.5rem !important; }
    .p-xl-2 {
      padding: 1rem !important; }
    .pt-xl-2,
    .py-xl-2 {
      padding-top: 1rem !important; }
    .pr-xl-2,
    .px-xl-2 {
      padding-right: 1rem !important; }
    .pb-xl-2,
    .py-xl-2 {
      padding-bottom: 1rem !important; }
    .pl-xl-2,
    .px-xl-2 {
      padding-left: 1rem !important; }
    .p-xl-3 {
      padding: 1.5rem !important; }
    .pt-xl-3,
    .py-xl-3 {
      padding-top: 1.5rem !important; }
    .pr-xl-3,
    .px-xl-3 {
      padding-right: 1.5rem !important; }
    .pb-xl-3,
    .py-xl-3 {
      padding-bottom: 1.5rem !important; }
    .pl-xl-3,
    .px-xl-3 {
      padding-left: 1.5rem !important; }
    .p-xl-4 {
      padding: 2.5rem !important; }
    .pt-xl-4,
    .py-xl-4 {
      padding-top: 2.5rem !important; }
    .pr-xl-4,
    .px-xl-4 {
      padding-right: 2.5rem !important; }
    .pb-xl-4,
    .py-xl-4 {
      padding-bottom: 2.5rem !important; }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 2.5rem !important; }
    .p-xl-5 {
      padding: 3.75rem !important; }
    .pt-xl-5,
    .py-xl-5 {
      padding-top: 3.75rem !important; }
    .pr-xl-5,
    .px-xl-5 {
      padding-right: 3.75rem !important; }
    .pb-xl-5,
    .py-xl-5 {
      padding-bottom: 3.75rem !important; }
    .pl-xl-5,
    .px-xl-5 {
      padding-left: 3.75rem !important; }
    .m-xl-auto {
      margin: auto !important; }
    .mt-xl-auto,
    .my-xl-auto {
      margin-top: auto !important; }
    .mr-xl-auto,
    .mx-xl-auto {
      margin-right: auto !important; }
    .mb-xl-auto,
    .my-xl-auto {
      margin-bottom: auto !important; }
    .ml-xl-auto,
    .mx-xl-auto {
      margin-left: auto !important; } }

  .text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

  .text-justify {
    text-align: justify !important; }

  .text-nowrap {
    white-space: nowrap !important; }

  .text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

  .text-left {
    text-align: left !important; }

  .text-right {
    text-align: right !important; }

  .text-center {
    text-align: center !important; }

  @media (min-width: 576px) {
    .text-sm-left {
      text-align: left !important; }
    .text-sm-right {
      text-align: right !important; }
    .text-sm-center {
      text-align: center !important; } }

  @media (min-width: 768px) {
    .text-md-left {
      text-align: left !important; }
    .text-md-right {
      text-align: right !important; }
    .text-md-center {
      text-align: center !important; } }

  @media (min-width: 992px) {
    .text-lg-left {
      text-align: left !important; }
    .text-lg-right {
      text-align: right !important; }
    .text-lg-center {
      text-align: center !important; } }

  @media (min-width: 1200px) {
    .text-xl-left {
      text-align: left !important; }
    .text-xl-right {
      text-align: right !important; }
    .text-xl-center {
      text-align: center !important; } }

  .text-lowercase {
    text-transform: lowercase !important; }

  .text-uppercase {
    text-transform: uppercase !important; }

  .text-capitalize {
    text-transform: capitalize !important; }

  .font-weight-light {
    font-weight: 300 !important; }

  .font-weight-normal {
    font-weight: 400 !important; }

  .font-weight-bold {
    font-weight: 700 !important; }

  .font-italic {
    font-style: italic !important; }

  .text-white {
    color: #fff !important; }

  .text-primary {
    color: #0065f2 !important; }

  a.text-primary:hover, a.text-primary:focus {
    color: #0050bf !important; }

  .text-secondary {
    color: #7f7f7f !important; }

  a.text-secondary:hover, a.text-secondary:focus {
    color: #666666 !important; }

  .text-success {
    color: #28a745 !important; }

  a.text-success:hover, a.text-success:focus {
    color: #1e7e34 !important; }

  .text-info {
    color: #17a2b8 !important; }

  a.text-info:hover, a.text-info:focus {
    color: #117a8b !important; }

  .text-warning {
    color: #ffc107 !important; }

  a.text-warning:hover, a.text-warning:focus {
    color: #d39e00 !important; }

  .text-danger {
    color: #ff3b30 !important; }

  a.text-danger:hover, a.text-danger:focus {
    color: #fc0d00 !important; }

  .text-light {
    color: #eff0f0 !important; }

  a.text-light:hover, a.text-light:focus {
    color: #d5d7d7 !important; }

  .text-dark {
    color: #000000 !important; }

  a.text-dark:hover, a.text-dark:focus {
    color: black !important; }

  .text-white {
    color: #fff !important; }

  a.text-white:hover, a.text-white:focus {
    color: #e6e6e6 !important; }

  .text-body {
    color: #000000 !important; }

  .text-muted {
    color: #7f7f7f !important; }

  .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important; }

  .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important; }

  .text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0; }

  .visible {
    visibility: visible !important; }

  .invisible {
    visibility: hidden !important; }

  @media print {
    *,
    *::before,
    *::after {
      text-shadow: none !important;
      box-shadow: none !important; }
    a:not(.btn) {
      text-decoration: underline; }
    abbr[title]::after {
      content: " (" attr(title) ")"; }
    pre {
      white-space: pre-wrap !important; }
    pre,
    blockquote {
      border: 1px solid #999999;
      page-break-inside: avoid; }
    thead {
      display: table-header-group; }
    tr,
    img {
      page-break-inside: avoid; }
    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3; }
    h2,
    h3 {
      page-break-after: avoid; }
    @page {
      size: a3; }
    body {
      min-width: 992px !important; }
    .container {
      min-width: 992px !important; }
    .navbar {
      display: none; }
    .badge {
      border: 1px solid #000000; }
    .table {
      border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
    .table-bordered th,
    .table-bordered td {
      border: 1px solid #e7e7e7 !important; } }

  .images-ratio {
    background-size: cover;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center; }
  .images-ratio::before {
    content: '';
    display: block;
    box-sizing: border-box; }
  .images-ratio.ratio-2-1::before {
    padding-top: 48.64%; }
  @media (max-width: 991.98px) {
    .images-ratio.ratio-2-1::before {
      padding-top: 100%; } }
  .images-ratio.ratio-4-2::before {
    padding-top: 54.62%; }
  @media (max-width: 991.98px) {
    .images-ratio.ratio-4-2::before {
      padding-top: 100%; } }
  .images-ratio.ratio-4-3::before {
    padding-top: 70.37%; }
  @media (max-width: 991.98px) {
    .images-ratio.ratio-4-3::before {
      padding-top: 100%; } }
  .images-ratio.ratio-4-5::before {
    padding-top: calc(109.24% + 30px); }
  @media (max-width: 991.98px) {
    .images-ratio.ratio-4-5::before {
      padding-top: 100%; } }
  .images-ratio.ratio-16-9 {
    max-height: 600px; }
  .images-ratio.ratio-16-9::before {
    padding-top: calc(109.24% + 30px); }
  @media (max-width: 991.98px) {
    .images-ratio.ratio-16-9::before {
      padding-top: 100%; } }
  .images-ratio.ratio-3-1::before {
    padding-top: 36.93%; }

  .section-break {
    background: #f2f2f2;
    text-align: center;
    line-height: 30px;
    font-size: 11px; }

  .lead {
    font-size: 1.25rem;
    font-weight: 300;
  }

  li {
    font-size: 16px;
    font-weight: 400;
  }

  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 800;
    line-height: 1.5;
    color: inherit;
  }
}

.dash1 {
  animation: dash 2s linear forwards;
}

@keyframes dash {
  to {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
  }
}

.printable {

  header {
    display: none !important;
  }

  .footer-buttons {
    display: none !important;
  }

  .cont1 {
    padding: 0 !important;
  }

}

.swal-overlay
{
  z-index: 100000000000 !important
}