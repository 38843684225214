
.ignore-css{
    all:unset;
}


/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top1: 61px;
    background-color: white;
    width: 1175px;
    z-index: 1;
    box-shadow: inset 0 -7px 9px -7px rgba(0,0,0,0.4);
    border-radius: 5px;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 60px;
}




.ql-picker.ql-placeholder > span.ql-picker-label::before {
    content: 'Insert Variable...__';
}

.ql-picker.ql-placeholder > span.ql-picker-options > span.ql-picker-item::before {
    content: attr(data-label);
}

.ql-placeholder-content{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    display: block;
}
.ql-placeholder-content[data-type=plain]{
    user-select: auto;
    display: inline-block;
}
.ql-placeholder-content[data-type=block] > span, .ql-placeholder-content[data-type=image] > span {
    display: block;
    border: dashed 3px rgba(34, 0, 128, 0.2);
    width: 100%;
    height: 190px;
    color1: transparent;
    background: repeating-linear-gradient(
            45deg,
            rgba(34, 0, 128, 0.15),
            rgba(34, 0, 128, 0.15) 10px,
            rgba(34, 0, 128, 0.05) 10px,
            rgba(34, 0, 128, 0.05) 20px
    );
    cursor: pointer;
}
.ql-placeholder-content > span.selected {
    border-color: #220080;
    border-width: 3px;
}
.ql-placeholder-content .placeholder-component {
    padding: 20px;
    pointer-events:none;
    display: block;
    max-height: 200px;
}
.ql-placeholder-content .placeholder-component img {
    float: left;
    margin-right: 20px;
    margin-top: -20px;
    pointer-events:none
}
.ql-placeholder-content .placeholder-component strong {
    font-size: 21px;
    pointer-events:none
}
.ql-placeholder-content .placeholder-component span {

    font-size: 16px;

}
.ql-placeholder-content .placeholder-component small {
    font-weight: 600;
    pointer-events:none;
    font-size: 13px;
}
.ql-placeholder-content1:before {
    text-align: center;
    content: attr(data-label) ' ' attr(data-description) ;
    font-size: 42px;
    font-weight: bolder;
    position: absolute;
    margin: auto;
    margin-top: 42px;
    width: 100%;
    display: inline-block;
    pointer-events:none
}



#toolbar-container .btn-save {
    all: unset;
    font-size: 18px;
    font-weight: 500;
    padding: 6px 12px;
    margin-bottom: 0;
    border-radius: 4px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
}
#toolbar-container .btn-save:focus,
#toolbar-container .btn-save:active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
#toolbar-container .btn-save:hover,
#toolbar-container .btn-save:focus {
    color: #333;
    text-decoration: none;
}
#toolbar-container .btn-save:active {
    background-image: none;
    outline: 0;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
}

/* default
---------------------------- */
#toolbar-container .btn-save-default {
    color: #fff;
    background-color: #182ce2;
    border-color: #ccc;
}
#toolbar-container .btn-save-default:focus {
    color: #fff;
    background-color: #182ce2;
    border-color: #8c8c8c;
}
#toolbar-container .btn-save-default:hover {
    color: #fff;
    background-color: #182ce2;
    border-color: #adadad;
}
#toolbar-container .btn-save-default:active {
    color: #fff;
    background-color: #182ce2;
    border-color: #adadad;
}