/** @format */

.react-tagsinput {
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  padding-left: 5px;
  padding-top: 5px;
}

.react-tagsinput > span {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.react-tagsinput--focused {
  border-color: #2cbda5;
}

.react-tagsinput-tag {
  background-color: #2cbda5;
  border-radius: 2px;
  border: 1px solid #2cbda5;
  color: white;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
}

.react-tagsinput-tag a::before {
  content: ' ×';
  color: white;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  flex-grow: 1;
}
